<div [ngSwitch]="session.user?.theme_id">
  <div *ngSwitchDefault class="tos-box">
    <h3 class="tos-header">Our Terms of Service for the Service</h3>
    <p>Last Updated July 5, 2023</p>
    <amp-pc-tos-line></amp-pc-tos-line>
    <p>***IMPORTANT, PLEASE READ THESE ONLINE TERMS OF SERVICE CAREFULLY. HEALTHTRUST, LLC IS THE ADMINISTRATOR OF THIS
      PROGRAM.</p>

    <p>
      AdvantageTrust, a program of HealthTrust Purchasing Group, LP (“<strong>HealthTrust</strong>,”
      “<strong>we</strong>,” “<strong>our</strong>” or “<strong>us</strong>”), permits eligible companies that have
      completed their online registration to access and use the Platform available through <strong>websites</strong>
      under the control of HealthTrust (the “Websites”) to complete certain GPO-related transactions (the
      “<strong>Service</strong>”), conditioned on acceptance of these Terms of Service (“<strong>Terms of
        Service</strong>”).
    </p>

    <strong>1. Acceptance of Terms of Service</strong>
    <p>These Terms of Service constitute a legal agreement between HealthTrust, the eligible corporation, limited
      liability company or other entity that elects to participate in the Service, whether as an entity that operates a
      medical practice, medical service, health and human service or other eligible service that has entered into our
      Participation Agreement (as defined below) and remains a member of our group purchasing organization (GPO) (a
      “<strong>Member</strong>”) and each employee or representative of each Member who is issued a user name and
      password (collectively, “<strong>Authorized User</strong>,” “<strong>you</strong>” or “<strong>your</strong>”).
      Member is solely responsible for the acts and omissions of its Authorized Users and of any individual using the
      user name and password of its Authorized Users. As used herein, the terms “you,” “your” and words of similar
      import mean both Member in its legal capacity and the Authorized User in his or her individual capacity.
    </p>

    <p>
      <strong>If you are eligible to be a Member or Authorized User and desire to obtain a license to access and use the
        Service, you will be required to demonstrate your agreement to these Terms of Service by reviewing these Terms
        of Service and by indicating your acceptance as part of the registration process. By indicating your acceptance,
        you acknowledge that you have read, understand and agree to be bound by these Terms of Service. If you do not
        agree to be bound, you should not sign the “Signature” box, and you will not be provided access to the
        Service.</strong>
    </p>

    <p>These Terms of Service include a disclaimer of warranties, exclusive remedies and a disclaimer of liability, as
      well as indemnification by you, in Sections 8, 9, 10 and 11. Please review those sections (and all other terms)
      carefully. In addition, the Terms of Use for the Website contain additional restrictions on your use of the
      Website; such terms are hereby incorporated by reference. Please review those sections (and all other terms)
      carefully.</p>

    <p>Please also review our <strong>Privacy Policy http://purchaseclinic.com/privacy-policy/</strong>, which contains
      important information regarding the collection, use, disclosure and protection of your information, and our
      <strong>Terms of Use http://purchaseclinic.com/terms-of-use/</strong>, which sets forth the terms governing use of
      the Websites. Your agreement to these Terms of Service includes your agreement to our Privacy Policy and the Terms
      of Use. If there is a conflict between these Terms of Service, the Terms of Use and the Privacy Policy, the
      Privacy Policy shall have precedence with respect to the subject matter covered by it, the Terms of Use shall have
      precedence over general use of the Websites (as a visitor to the Websites) and otherwise these Terms of Service
      shall have precedence. If there is a conflict between these Terms of Service and the Participation Agreement, the
      Participation Agreement will control.
    </p>

    <p>
      <strong>NOTE THAT</strong>, HealthTrust may make changes to the Service or these Terms of Service at any time. We
      encourage you to review our Websites and these Terms of Service regularly for any such changes. Your continued
      access to or use of the Service shall be deemed your acceptance of these changes and the reasonableness of these
      standards for notice.
    </p>

    <p><strong>2. Eligibility to Use the Service; Registration.</strong></p>

    <p>The Service is limited to corporations, limited liability companies and other legal entities organized under the
      laws of a State in the United States, the employees and authorized representatives designated as authorized users
      by such legal entities and individuals resident in the United States that are eligible to and have entered into a
      Participation Agreement that has not been terminated. If You currently are a committed member of a third party
      group purchasing organization and/or obligated by contract to a third party to purchase exclusively from that
      organization, you are not eligible to become an Authorized User.</p>

    <p>If you are eligible to become an Authorized User, you or your authorized representatives must set up an
      Authorized User account by completing the registration process prior to first using the Service, and each of your
      Authorized Users must also agree to these Terms of Service. You represent and warrant that the person establishing
      the account for each Authorized User is authorized to bind Authorized User to these Terms of Service. As part of
      the registration process, you must provide HealthTrust with complete and accurate information for each Authorized
      User, as prompted by the applicable registration form, including a valid email address. Each individual Authorized
      User will also choose a password and a user name. You agree that all information supplied by you in establishing
      your account(s) is accurate and complete, that you will maintain and promptly update the information, and that you
      consent to the storage of your information and content in the Service. If HealthTrust has reasonable grounds to
      suspect that your information is inaccurate or incomplete, HealthTrust may suspend or terminate your account(s),
      disable your password and refuse any and all current and future use of the Service, without prior notice.</p>

    <p>When you have completed the registration process, you will be authorized to use the Service until termination.
      Each Authorized User is entirely responsible for maintaining the confidentiality of user names and passwords. You
      agree to immediately notify HealthTrust of any known or suspected unauthorized use of your passwords, user names
      or accounts or any other breach of security. To the maximum extent permitted by applicable law, HealthTrust will
      not be liable for any loss that you may incur as a result of someone else using your passwords, user names or
      accounts, either with or without your knowledge, or for any inaccuracies or omissions in your data. However, you
      could be held liable for losses incurred by HealthTrust or a third party due to someone else using your account,
      user name or password.</p>

    <p><strong>3. Permitted Use of the Service</strong></p>

    <p>If you eligible to become an Authorized User, have agreed to these Terms of Service and your rights have not been
      terminated, HealthTrust will provide you access to the Service. You agree to access and use the Service, to the
      extent permitted by the functionality of the Websites, solely for the purposes of (a) accessing the HealthTrust
      platform (the “<strong>Platform</strong>”) in order to complete the GPO-related transactions permitted by the
      Websites, (b) accessing HealthTrust’s tools, interfaces, templates, designs, guidelines and other content
      incorporated into the Platform (“<strong>HealthTrust Content</strong>”) and (c) tracking your account. You
      understand that your access rights are personal, nonexclusive and nontransferable, that your rights may be
      terminated by HealthTrust if you do not abide by these Terms of Service and that you may have liability to
      HealthTrust and third parties if you misuse the Service.
    </p>

    <p><strong>4. Entering into Agreements; Transactions</strong></p>

    <p><strong>Participation Agreements</strong></p>

    <p>Authorized Users that are eligible to do so must enter into a GPO participation agreement (“<strong>Participation
        Agreement</strong>”) by completing the registration process made available through the Websites and signing the
      “Signature” box or giving any other required form of assent, at which time the Authorized User will be a “Member”
      and subject to the terms and conditions of the Participation Agreement. The registration process may permit
      eligible entities and individuals to become Authorized Users and Members through a single transaction, in which
      case by signing the “Signature” box (or giving any other required form of assent), you agree both to these Terms
      of Service and to the terms of the Participation Agreement.
    </p>

    <p>If you are Member and wish to do so, you may access contract pricing for goods and services from third-party
      vendors that are made available to you through your Participation Agreement by clicking on a vendor’s link
      provided through the Websites. If you click a vendor’s link provided through the Websites to make such a purchase
      from a vendor, you will leave the Websites, and any purchase you make will be subject to the terms and conditions
      of the vendor offering such goods and services, including the terms of use, terms of service, privacy policy and
      other such terms of the vendor’s websites. Such terms and conditions may be different from the terms and
      conditions of these Websites.</p>

    <p>
      <strong>Members acknowledge and agree that transactions for the purchase of goods and services from vendors are
        agreements solely between the Member and the vendor of those goods and services and, to the greatest extent
        permitted by applicable law, Members disclaim all liability against HealthTrust and its affiliates for any loss
        or claim arising out of any such transaction. HealthTrust and its affiliates make no representation or warranty
        about vendors whose website links are made available through the Websites or about the goods and services
        offered by such vendors</strong>.
    </p>

    <p><strong>Compliance</strong></p>

    <p>Authorized User is solely responsible for complying with all laws applicable to its use of the Service.
      Authorized User agrees that it shall not make any statements in connection with its use of the Service that is
      false or misleading. Authorized User is solely confirming its eligibility to become an Authorized User and access
      the Service, and is solely responsible for complying with all applicable terms and conditions imposed by
      third-party social networking services used in connection with the Service.</p>

    <p><strong>Reporting Fraudulent Transactions</strong></p>

    <p>If you believe someone has fraudulently taken actions under your account, you must notify HealthTrust immediately
      at <u>support@purchaseclinic.com</u></p>

    <p><strong>Authorized User Content</strong></p>

    <p>If permitted by the functionality of the Websites, you may upload content, logos and designs relating to your
      business and incorporate such content (“<strong>Authorized User Content</strong>”) into the Platform. You hereby
      grant us and others, including third parties, a right and license to use, copy, host, display modify and create
      derivative works of all Authorized User Content you upload into the Service for the purposes of making the Service
      available, for the performance of our obligations under these Terms of Service and for HealthTrust’s marketing
      purposes (as described in Section 14 below). You represent and warrant (a) that you have all necessary rights and
      licenses to grant the rights granted herein and for us and our vendors to use and display all Authorized User
      Content as contemplated herein, (b) that the Authorized User Content does not infringe upon, violate or
      misappropriate the intellectual property rights of any third parties, (c) that the Authorized User Content is not
      libelous, defamatory, offensive, insulting, derogatory or likely to bring HealthTrust into disrepute, and (d) that
      the Authorized User Content does not include any material or information that is subject to a third party’s right
      of privacy or that, if disclosed to third parties, would violate or breach any applicable law, rule or regulation.
      Without limiting the foregoing, you represent and warrant that the Authorized User Content you upload into the
      Service will not include any Social Security numbers, personal health information or non-public financial
      information. As between you and HealthTrust, you retain ownership of Authorized User Content, subject to the
      limited rights granted herein.
    </p>

    <p><strong>5. Other Restrictions</strong></p>

    <p>HealthTrust reserves all rights in the Service not granted in these Terms of Service. Without limiting the
      foregoing, unless expressly permitted hereunder, by using the Service you agree not to:</p>

    <p></p>
    <ol>
      <li>translate, modify or create derivative works of the Service;</li>
      <li>upload, download, recreate, display, perform, post, reproduce or copy the Service, except to the extent
        permitted herein;</li>
      <li>disclose, publish, distribute, sell, assign, lease, sublicense or transfer the Service;</li>
      <li>attempt to derive the source code, source files or structure of the software contained in the Service by
        reverse engineering, disassembly, decompilation or any other means;</li>
      <li>use the Service to create a service bureau or for any other use involving processing of data for other persons
        or entities;</li>
      <li>use the Service except in accordance with all applicable law;</li>
      <li>introduce into the Service any “malware,” such as, but not limited to, viruses, worms and Trojan Horses;</li>
      <li>use deep-links, page scrapes, web crawlers, web robots, spiders, wanderers, web scutters, ants, automatic
        indexers, bots, worms, or other such devices, or programs, algorithms or methodologies which do the same things
        in connection with the Service, or use other automated processes to access or use the Service;</li>
      <li>attempt to access the accounts of any user of the Service other than your own account;</li>
      <li>post, transmit or link from any unlawful, infringing, misleading, deceptive, threatening, libelous,
        defamatory, plagiarized, fraudulent, harassing, obscene, discriminatory, inflammatory, pornographic or profane
        material, spam or any material that could constitute or encourage conduct that would be considered a criminal
        offense, give rise to civil liability, or would otherwise violate applicable law;</li>
      <li>use the Service in any manner that could damage, disable, undermine, overburden or impair the Service or the
        servers on which it runs or interfere with any other party’s use of the Service;</li>
      <li>obtain or attempt to obtain any content through any means not intentionally made available or provided for
        through the Service; or</li>
      <li>use any of the logos, trademarks, service marks or other indicators of origin appearing on the Service.</li>
    </ol>

    <strong>6. Copyrights and Other Proprietary Rights</strong>
    <p></p>

    <p>Authorized User acknowledges and agrees that HealthTrust and its licensors retain all ownership rights in the
      Service including the Websites, all HealthTrust software technology that is incorporated into or made available
      through the Websites, any downloadable software or related technical information for Platform integration
      (including, if applicable, application program interfaces) that is made available through the Websites and all
      modifications and improvements thereto (“<strong>HealthTrust Technology</strong>”) and that you do not receive any
      ownership rights or license rights, except as set forth herein, by accessing or using the Service or consenting to
      these Terms of Service. The entire content of the Service, the Websites and the HealthTrust Technology,<strong>
      </strong>including but not limited to text, design, software, photography, video, graphics, music, sound,
      information and the selection, coordination, arrangement, and enhancement thereof, is protected under the
      copyright laws of the United States, international treaties and other intellectual property laws (including
      without limitation the copyright in the selection, coordination, arrangement and enhancement of all content).
    </p>

    <p><strong>7. Confidential Information</strong></p>

    <p><strong>Definition</strong></p>

    <p>“<strong>Confidential Information</strong>” means information relating to the products, services or business
      affairs of HealthTrust which is of a proprietary or confidential nature, whether communicated orally or in
      writing, including, but not limited to, pricing information, print product printing volumes, the HealthTrust
      Technology, and HealthTrust’s concepts, techniques, processes, designs, documentation, flow-charts, diagrams,
      instructions, computer programs, technical know-how, information and trade secrets disclosed by HealthTrust to
      Authorized Users under this Agreement (each, a “<strong>Recipient</strong>”). Confidential Information shall also
      include any information of a confidential nature concerning HealthTrust’s financial affairs or business and any
      information HealthTrust has received from others which HealthTrust is obligated to treat as confidential or
      proprietary.
    </p>

    <p><strong>No Unauthorized Disclosure/Use</strong></p>

    <p>The Recipient acknowledges that irreparable injury and damage will result from disclosure of the Confidential
      Information to unauthorized third parties or from utilization of the Confidential Information for purposes other
      than those connected with the business relationship of the parties hereto. The Recipient shall not, without the
      prior written consent of HealthTrust, disclose any Confidential Information to any third party other than
      Authorized User’s employees and personnel who have a need to know and who are bound by obligations of
      confidentiality consistent with this Section 7. The Recipient shall not use the Confidential Information except to
      perform its obligations and exercise its rights under these Terms of Service. The Recipient shall not be in breach
      of this section if disclosure of Confidential Information is made pursuant to subpoena or other compulsory
      judicial process, the Recipient promptly notifies HealthTrust of such subpoena or other compulsory process, and
      provides reasonable assistance at HealthTrust’s request and expense so that HealthTrust may seek a protective
      order or take such other action it deems necessary to protect its interests.</p>

    <p><strong>Protection of Confidential Information</strong></p>

    <p>The Recipient agrees to take all necessary security precautions to protect the Confidential Information from
      unauthorized disclosure, including, without limitation, restricting access thereto and protecting documents
      containing Confidential Information from theft and from the unauthorized duplication or discovery of their
      contents.</p>

    <p><strong>Return of Materials</strong></p>

    <p>At any time upon HealthTrust’s request, the Recipient will promptly return to HealthTrust all written material,
      in whatever form or media, containing or reflecting any Confidential Information of HealthTrust and will not
      retain any copies, extracts, or other reproductions, in whole or in part, of such written material. All documents,
      memoranda, notes, and other writings whatsoever, in whatever form or media, (including all copies, extracts, or
      other reproductions) prepared by or on behalf of the Recipient that are based on the information contained in the
      Confidential Information of HealthTrust will be destroyed, and such destruction will be certified in writing to
      HealthTrust by the Recipient. The return of such material will not relieve the Recipient’s obligation of
      confidentiality or other obligations hereunder.</p>

    <p><strong>8. Disclaimer</strong></p>

    <p>
      <strong>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE SERVICE IS
        PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTIES OF ANY KIND, AND HEALTHTRUST AND ITS LICENSORS
        DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND WARRANTIES ARISING FROM COURSE
        OF DEALING, COURSE OF PERFORMANCE AND USAGE OF TRADE. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.
        FURTHERMORE, HEALTHTRUST AND ITS LICENSORS AND VENDORS DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR
        ERROR FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
        CODE OR THAT THE USE OF THE SERVICE WILL COMPLY WITH THE RULES OF ANY THIRD PARTY SOCIAL NETWORKING WEBSITE. ANY
        MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND
        RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
        FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</strong>
    </p>

    <p><strong>9. Exclusive Remedy</strong></p>

    <p>If you are dissatisfied with the Service (including without limitation these Terms of Service), you acknowledge
      and agree that your <strong>sole and exclusive remedy</strong> is to discontinue using the Service.</p>

    <p><strong>10. Limitations on Liability</strong></p>

    <p>
      <strong>NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS OF SERVICE OR OTHERWISE, TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, HEALTHTRUST AND ITS VENDORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY LOST OR
        CORRUPTED DATA OR CONTENT, ANY ERRORS CAUSED BY THE TOOLS AVAILABLE THROUGH THE WEBSITE, ANY TERMINATION,
        SUSPENSION OR OTHER LOSS OF YOUR OR A THIRD PARTY’S SOCIAL NETWORKING ACCOUNT OR ANY SPECIAL, INDIRECT,
        INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF USE OF THE SERVICE OR ANY SUBJECT
        MATTER OF THESE TERMS OF SERVICE, EVEN IF HEALTHTRUST HAS BEEN ADVISED OF OR COULD HAVE FORESEEN THE POSSIBILITY
        OF SUCH DAMAGES UNDER ANY THEORY OF LIABILITY. IN NO EVENT WILL HEALTHTRUST HAVE ANY LIABILITY TO YOU OR ANY
        THIRD PARTY ARISING FROM YOUR USE OF THE SERVICE WHICH IN THE AGGREGATE IS MORE THAN ONE HUNDRED DOLLARS
        ($100.00).</strong>
    </p>

    <p><strong>11. Indemnification</strong></p>

    <p>HealthTrust and its licensors, vendors, insurers, agents and commercial partners and our respective officers,
      directors, stockholders, employees and agents are, collectively, the “<strong>HealthTrust Parties</strong>.” You
      agree to indemnify and hold the HealthTrust Parties harmless from and against any and all claims, demands,
      liabilities, losses, damages, costs and expenses, including reasonable attorney’s fees, that arise from any
      content or data you post, transmit or link from on the Websites or the Service, your misuse of the Websites or the
      Service, including, but not limited to, your violation of these Terms of Use or, if applicable to you, the Terms
      of Service, your violation of applicable law or your violation of any rights of any third party.
    </p>

    <p><strong>12. Termination</strong></p>

    <p>Subject to applicable law, HealthTrust reserves the right to terminate, suspend or deny, in its sole discretion,
      your access to all or any portion of the Service, without prior notice, if (a) Authorized User breaches or
      threatens to breach these Terms of Service, (b) Authorized User breaches or threatens to breach, as applicable,
      the applicable Participation Agreement, (c) an officer or executive of Authorized User inform us in writing that
      you are no longer authorized to use the Service, (d) if required to do so by operation of law, or (e) no activity
      occurs in your account or in the account of Authorized User for a period of ninety (90) days. In addition, if
      Authorized User is not a party to a then-current Participation Agreement, either party may terminate this
      agreement for convenience upon thirty (30) days’ prior written notice, provided that HealthTrust may give such
      notice by email using the email address you provided to us in registration. If either party terminates for
      convenience in accordance with the prior sentence prior to the termination of a Participation Agreement,
      termination shall be effective thirty (30) days after termination of the last effective Participation Agreement.
      Upon termination of this Agreement, you acknowledge and agree that HealthTrust may immediately deactivate or
      delete your account and all related data and files in your account, bar any further access to such data, files and
      the Service, and disable your password on termination of these Terms of Service. The following terms shall survive
      any termination of these Terms of Service: Sections 5, 6, 7, 9, 10, 11, 12, 13, 14 and 15.</p>

    <p><strong>13. Governing Law; Jurisdiction; Venue</strong></p>

    <p>HealthTrust controls the Service from its offices within the United States, and your data is stored in servers
      managed by HealthTrust in the United States. HealthTrust makes no representation that the Service is appropriate,
      may be downloaded, or is available for use outside the United States. Access to the Service where the content or
      access or use of the Service is illegal is prohibited. Those who choose to access and use the Service from outside
      the United States do so on their own initiative, at their own risk, and are responsible for compliance with
      applicable local laws and U.S. export and other applicable laws.</p>

    <p>The laws of the State of Tennessee will govern these Terms of Service, without reference to its conflicts of law
      principles, except that the federal law of the United States shall apply to questions regarding the validity,
      infringement or enforceability of U.S. federal patent, copyright and trademark rights relating in any way to these
      Terms of Service, or the Service. English is the only language applicable to these Terms of Service. You agree to
      submit to the exclusive jurisdiction of, and waive any venue objections and defenses of lack of personal
      jurisdiction against, the State and Federal courts located in Tennessee, except that you acknowledge that any
      breach of Sections 3, 5 and 7 cannot reasonably or adequately be compensated by damages in an action at law and
      that a breach or threatened breach of such provisions shall cause HealthTrust irreparable injury and damage, and
      HealthTrust shall be entitled, in addition to any other remedies it may have, to preliminary and permanent
      injunctive and other equitable relief to prevent or curtail any actual or threatened breach in any court of
      competent jurisdiction. Process may be served on you in the manner authorized by applicable law or court rule.</p>

    <p>ALL PARTIES TO ANY LITIGATION MUST BE INDIVIDUALLY NAMED. THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO
      BE LITIGATED ON A CLASS ACTION OR CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A PURPORTED
      REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER AUTHORIZED
      USERS, OR OTHER PERSONS.</p>

    <p><strong>14. Marketing by HealthTrust; Use of Authorized User Logos</strong></p>

    <p>Authorized User agrees that HealthTrust may list Authorized User’s business identity in marketing materials and
      at marketing events (like trade shows) and to display Authorized User’s logos (to the extent Authorized User is a
      legal entity and not an individual) on such lists, in such marketing materials and at such marketing events.
      HealthTrust agrees to follow Authorized User’s reasonable instructions regarding the display of any Authorized
      User trademarks. With Authorized User’s written permission, HealthTrust may prepare and publish case studies
      describing Authorized User’s experience with the Platform for HealthTrust’s marketing purposes. Unless Authorized
      User agrees in writing, such case studies will not identify Authorized User by name.</p>

    <p><strong>15. Miscellaneous Terms</strong></p>

    <p>If any provision of these Terms of Service is held to be unenforceable, that provision will be removed and the
      remaining provisions will remain in full force. The failure of either you or HealthTrust to require performance by
      the other party of any provision of these Terms of Service will not affect the right to require performance at any
      time in the future; nor will the waiver by either party of a breach of any provision be taken or held to be a
      waiver of the provision itself. These Terms of Service (including without limitation our Privacy Policy and Terms
      of Service) are the entire and exclusive agreement between you and HealthTrust with respect to your access and use
      of the Service.<strong> </strong>You may not assign this agreement or any portion thereof, in whole or in part,
      including by operation of law, without HealthTrust’s prior written consent. HealthTrust may freely assign this
      agreement and delegate its obligations hereunder. Except as otherwise provided herein, any notice permitted or
      required to be given under these Terms of Service shall be deemed sufficient if given by registered or certified
      mail, postage prepaid, return receipt requested, by private courier service or by facsimile or email addressed to
      your address as provided upon your registration and/or to HealthTrust, attn.: Support at 10221 River Road #60053,
      Potomac, MD, 20854, or to such other addresses as the parties may designate by like notice from time to time. A
      notice so given shall be effective upon (a) receipt by the party to which the notice is given, or (b) on the fifth
      day following domestic mailing or the tenth day following international mailing, whichever occurs first. Any
      notice permitted under this Agreement to be given to HealthTrust via email will be effective only upon actual
      receipt by HealthTrust of an email message from an email address registered in connection with your account. Any
      delays in performance by HealthTrust under this Agreement will not be considered a breach of this Agreement. There
      are no third party beneficiaries to this Agreement.
    </p>

    <h3 class="tos-header">Participation Agreement</h3>

    <p>The following are the terms and conditions for Locations to participate in the HealthTrust Group Purchasing
      Program offered by HealthTrust Purchasing Group, L.P. (“HealthTrust”), a Delaware limited partnership with
      headquarters at 1100 Dr. Martin Luther King Jr Blvd., Suite 1100, Nashville, Tennessee 37203. This Group
      Purchasing Organization Participation Agreement is entered into by and between HealthTrust, and “Member” indicated
      below. HealthTrust and Member agree as follows:</p>

    Date: <strong>{{acceptanceDate}}</strong><br>
    <span *ngIf="ip">Your IP Address is <strong>{{ip}}</strong></span>

    <h2 style="margin: 1em 0 0.5em; padding: 0; font-size: 150%">Member</h2>

    <p>Organization’s Legal Name: <strong>{{loc.facility_name}}</strong><br />
    </p>

    <p>Street Address:<br>
      <span style="font-weight: bold">{{loc.address}} {{loc.address2}}<br> {{loc.city}}, {{loc.state}}
        {{loc.zip}}</span>
    </p>
    <p>Phone Number: <strong>{{user.phone}}</strong></p>

    <p><strong>Contact person</strong> to receive information about HealthTrust programs and savings opportunities:</p>
    <p>Name: <strong>{{user.fname}} {{user.lname}}</strong><br>
      Title: <strong>{{user.job_title}}</strong><br>
      E-Mail Address: <strong>{{user.email}}</strong><br>
    </p>

    <p>
      <strong>Additional Locations</strong> --- This Agreement shall apply collectively to all Member’s current and
      future Locations. Current Location(s) are identified above and may be listed in Schedule 1. A list of all Member’s
      Locations to which this Agreement applies is viewable in Member’s Health Trust account and is available upon
      request. Member warrants that it owns and/or manages Locations and has legal authority to enter into this
      Agreement on their behalf. Member shall provide prompt written notice of all additions/changes to Locations.
    </p>
    <p>
      <strong>Exclusive Group Purchasing Agency</strong>: Member authorizes HealthTrust as its exclusive group
      purchasing and contracting services agent to negotiate and enter into agreements with vendors in order to make
      agreements available to Member.
    </p>
    <p>
      <strong>Vendor Agreements</strong>: The agreements made available to Member shall be cumulatively referred to as
      “Vendor Agreements”. Member agrees to comply with the terms and conditions of any Vendor Agreement through which
      it chooses to make any purchase.
    </p>
    <p><strong>Own Use</strong>: Member represents and warrants that any purchase made through any Vendor Agreement
      under this Agreement will be for Member’s “Own Use” and not for resale.</p>
    <p><strong>Member Updates; Authorization to Use Member Signature and Information for Enrollment:</strong> Member is
      required to update its enrollment with HealthTrust if there is a change to its contact person or relevant changes
      in the scope of authority of the person who executed this Agreement on Member&rsquo;s behalf. Member understands
      and acknowledges that HealthTrust is authorized to rely upon and use the Member information and signature of the
      authorized person provided during the enrollment and any subsequent enrollment processes unless Member notifies
      HealthTrust of a change. Member authorizes HealthTrust to capture and use that information and electronic
      signature of the authorized person provided during enrollment processes to populate documentation necessary to
      enroll Member in GPO, including declaration, commitment and other required vendor and distributor forms, and to
      update or change Member&rsquo;s buying group affiliation in accord with this Agreement. Authorized uses of such
      information and signature by HealthTrust include execution of letters of commitment and ensuring Member is in an
      appropriate tier based on its ability to commit, but HealthTrust will not use such information to obligate Member
      to may any purchases. HealthTrust will maintain a copy of any documentation it has submitted using Member&rsquo;s
      information and signature of its authorized person. Member may opt out of this authorization or withdraw it at any
      time by sending a request in writing to: <a
        href="mailto:support@purchaseclinic.com">support@purchaseclinic.com</a></p>
    <p>
      <strong>Rebates</strong>: Any rebates due to Member based on Member’s purchases through any Vendor Agreement, if
      received by HealthTrust, will be forwarded to Member at least quarterly. Member is solely responsible for
      reporting any such rebate as required by law.
    </p>
    <p>
      <strong>Administrative Fees</strong>: HealthTrust discloses to Member that it receives administrative fees from
      contracted vendors based on purchases made by Member. The administrative fee varies according to each Vendor
      Agreement and is generally (3%) or less of the purchase price of the goods or services purchased by Member;
      HealthTrust will report to Member any administrative fee that is greater than three percent (3%). HealthTrust will
      report to Member annually, and to the Secretary of the U.S. Department of Health and Human Services upon request,
      the fees received under each Vendor Agreement for purchases made by Member.
    </p>
    <p>
      <strong>Global Products.</strong> Member acknowledges that HealthTrust is engaged in a program to achieve savings
      on products (“Global Products”) by sourcing them internationally through coordinated arrangement with third
      parties. Due to the investment of HealthTrust in this program, as well as the services and clinical expertise that
      HealthTrust will dedicate to sourcing products globally, pricing for Global Products made available to Locations
      for purchase may include a fee payable to HealthTrust (a “Global Sourcing Fee”). While the Global Sourcing Fee is
      not calculated based on a specific formula related to sourcing costs, the amount of the Global Sourcing Fee will
      range from zero percent (0%) up to a maximum of five percent (5%) of the sourcing costs. As used in this Section,
      “sourcing costs” includes costs for purchasing Global Products from the manufacturer and transportation and
      logistics costs for delivery to the third party warehouse
    </p>
    <p>
      <strong>Business Associate Agreements.</strong> HealthTrust may provide on or through the HealthTrust Member
      Portal copies of BAAs with certain Vendors. Member agrees it is solely responsible for determining if BAAs meet
      Member’s own legal and other requirements. HealthTrust does not accept, and expressly disclaims any legal,
      financial, or other obligation relating to Member’s reliance on: (i) the terms of any BAA; or (ii) the absence of
      a BAA indicating that no BAA was required. Member is solely responsible for determining, with respect to Member:
      (a) whether a BAA with a Vendor is required; and (b) the content of any such BAA is acceptable. It is not the
      intent of this relationship to have PHI disclosed by Member or any Location to HealthTrust or to, in any way, make
      HealthTrust a business associate (as defined in HIPAA) to Member or any Location.
    </p>
    <p>
      <strong>Confidentiality</strong>: Member agrees to keep strictly confidential, hold in trust, and not disclose any
      Confidential Information (defined below) received from HealthTrust and its Affiliate GPOs. “Confidential
      Information” means any trade secrets or proprietary information including but not limited to programs, services,
      systems, pricing, agreements or information technology shared with Member by HealthTrust.
    </p>
    <p>
      <strong>AUTHORITY; REPRESENTATIONS &amp; WARRANTIES</strong>: MEMBER REPRESENTS AND WARRANTS THAT IT IS AUTHORIZED
      TO ENTER INTO THIS AGREEMENT WITH HEALTHTRUST AND THAT EXECUTION OF THIS AGREEMENT WILL NOT VIOLATE ANY AGREEMENTS
      BETWEEN MEMBER AND ANY THIRD PARTY, INCLUDING BUT NOT LIMITED TO OTHER GROUP PURCHASING ORGANIZATIONS. HEALTHTRUST
      AND ITS AFFILIATE GPOS MAKE NO EXPRESS OR IMPLIED REPRESENTATION OR WARRANTIES UNDER THIS AGREEMENT, INCLUDING BUT
      NOT LIMITED TO ANY VENDOR AGREEMENT, AS TO THE PRODUCTS OR SERVICES OFFERED THEREUNDER, OR THEIR SUITABILITY FOR
      ANY PURPOSE. ANY ANALYSIS, PRICING, OR PRODUCT INFORMATION IS PROVIDED AS IS WITH NO GUARANTEE OF ACCURACY OR
      COMPLETENESS.
    </p>
    <p>
      <strong>DISCLAIMER; LIMITATION OF LIABILITY</strong>: HEALTHTRUST DOES NOT MAKE, AND EXPRESSLY DISCLAIMS, ANY
      WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR ANY OTHER WARRANTY, EXPRESSED OR IMPLIED, AS
      TO ANY PRODUCTS AND SERVICES SOLD BY ANY VENDOR;MEMBER ACKNOWLEDGES THAT MEMBER IS SOLELY RESPONSIBLE FOR DECIDING
      TO ENTER THIS AGREEMENT AND DETERMINING WHICH IF ANY PRODUCTS OR SERVICES TO PURCHASE THROUGH THE VENDOR
      AGREEMENTS. MEMBER ACKNOWLEDGES AND AGREES THAT HEALTHTRUST, AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
      SHAREHOLDERS, EMPLOYEES, AGENTS AND CONTRACTORS SHALL NOT HAVE ANY LIABILITY OF ANY NATURE OR KIND WHATSOEVER FOR
      ANY PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, ATTORNEY’S FEES, COURT COSTS OR ANY OTHER DAMAGES, LEGAL,
      EQUITABLE OR OTHERWISE, CAUSED EITHER DIRECTLY OR INDIRECTLY BY OR RELATED TO THE PRODUCTS OR SERVICES OFFERED OR
      SOLD UNDER THE VENDOR AGREEMENTS. MEMBER AGREES TO HOLD HEALTHTRUST HARMLESS FROM ALL LOSSES, DAMAGES AND COSTS,
      OF ANY NATURE OR KIND WHATSOEVER (INCLUDING, BUT NOT LIMITED TO ATTORNEY’S FEES AND COURT COSTS) INCURRED BY
      HEALTHTRUST FROM MEMBER’S BREACH OF ANY OF THE TERMS, CONDITIONS, AND REPRESENTATIONS &amp; WARRANTIES OF THIS
      AGREEMENT OR IN ANY OTHER WAY RELATING TO THIS AGREEMENT.
    </p>
    <p>
      <strong>Indemnity.</strong> Member and its Locations shall indemnify and hold HealthTrust, HealthTrust general and
      limited partners, and their respective Affiliates, agents, officers, directors and employees (the “Indemnitees”)
      harmless from and against any and all losses, liabilities, damages, costs and expenses (whatsoever, including,
      without limitation, reasonable attorneys’ fees and litigation costs) in connection with third party claims or
      actions relating to acts or omissions of Participant and/or Locations which relate in any way to this Agreement or
      any Vendor Agreement, any products or any services, including, without limitation, any claims resulting from a
      failure to pay for products and services purchased by Member or any of its Locations, or from use of products and
      services.

    </p>
    <p>
      <strong>Assignment.</strong> Neither Participant, nor any Location may assign this Agreement, or any of its rights
      or obligations set forth herein, without the prior written consent of HealthTrust. No assignment in violation of
      the provisions of this Agreement shall vest any rights in any purported assignee. Participant consents to
      assignment by HealthTrust of its rights and obligations under this Agreement to any Affiliate of HCA Healthcare,
      Inc., or to a successor entity of HealthTrust as part of an internal reorganization of HealthTrust which results
      in HealthTrust being organized in one or more different legal entities or any other corporate form(s), whether
      through conversion, merger, or otherwise.
    </p>

    <p>
      <strong>Compliance with Law</strong>: Each party agrees that it shall at all times during the term of this
      Agreement comply with all applicable federal, state, and local laws and regulations in connection with its
      performance under this Agreement. It is the intent of the Parties to establish a business relationship that
      complies with the Medicare, and Medicaid anti-kickback statutes set forth at 42 U.S.C. §1320a-7b(b). Where a
      discount or other reduction in price is applicable, the Parties also intend to comply with the requirements of 42
      U.S.C. §1320a-7b(b)(3)(A) and the “safe harbor” regulations regarding discounts or other reductions in price set
      forth in 42 C.F.R. §1001.952(h). Member agrees to accurately report, under any state or federal program that
      provides cost or charge based reimbursement for the products and services, the net cost actually paid by Member
      and/or Locations.
    </p>
    <p>
      <strong>Access to Books and Records.</strong> Until the expiration of four (4) years after the Term (including any
      renewal term), HealthTrust shall make available to the Secretary of the United States Department of Health and
      Human Services, the United States Comptroller General, and their duly authorized representatives, in accordance
      with 42 C.F.R. § 420.300 et seq., this Agreement and all books, documents, and records necessary to certify the
      nature and extent of the costs of the services provided by HealthTrust hereunder. If HealthTrust carries out any
      of its duties hereunder through a subcontract worth ten thousand dollars ($10,000.00) or more over a twelve (12)
      month period with a related organization, the subcontract also shall contain an access clause to permit such
      access by the Secretary, the Comptroller General, and their duly authorized representatives to the related
      organization’s books, documents and records. The Parties agree that any attorney-client, accountant-client or any
      other legal privilege shall not be deemed waived by virtue of the provisions of this Section 9
    </p>
    <p>
      <strong>Term &amp; Termination</strong>: The initial term of this Agreement will be for one year commencing on the
      date it is signed by HealthTrust. This Agreement will renew annually for an additional one-year terms upon
      expiration of the current term, unless either party gives to the other party written notice of its intent to
      terminate the Agreement not less than 60 days prior to the end of the current term. EITHER PARTY MAY TERMINATE
      THIS AGREEMENT FOR ANY REASON BY PROVIDING THE OTHER PARTY 60 DAYS’ PRIOR WRITTEN NOTICE.
    </p>
    <p><strong>This Agreement constitutes the entire understanding and agreement between the parties. In witness
        whereof, the parties hereto have executed this Agreement by persons duly authorized.</strong></p>

    <p>Member Organization: <strong>{{loc.facility_name}}</strong>
      <span *ngIf="loc.dba"> &nbsp;(DBA {{loc.dba}})</span>
      <br>
      Member POC: <strong>{{user.fname}} {{user.lname}}</strong> <br>
      Member Title: <strong>{{user.job_title}}</strong> <br>
      Acceptance Date: <strong>{{acceptanceDate}}</strong> <br>
      <span *ngIf="ip">IP Address: <strong>{{ ip }}</strong></span>
    </p>
    <p><strong>For AdvantageTrust:</strong></p>
    <img src="assets/img/signature.png" alt="Signature" style="width: 100px; height: auto;">
    <p><strong>Zachary Sikes</strong></p>
    <p><strong>Senior Vice President, AdvantageTrust</strong></p>

    <p><strong>Distributor Primary GPO (Group Purchasing Organization)</strong></p>
    <p><strong>Designation Form</strong></p>
    <p>Request Date:</p>
    <p>Customer Account Name:</p>
    <p>Distributor Name/Company:</p>
    <p>Distributor Representative:</p>
    <p>Distributor Bill to Account Number(s):</p>
    <p>Any Ship To Account Under the Bill To(s) will be included in the Designation.</p>
    <p> </p>
    <p>Any Exclusions/Exception to the Above Account(s) Designations:</p>
    <p>Please Enter Distributor Account Number(s) separated by commas</p>
    <p> </p>
    <p>Please Designate your Preferred / Primary GPO for the above Account(s)</p>
    <p>HealthTrust (HPG): </p>
    <p>HealthTrust GPOID(S):</p>
    <p>Please Check &ldquo;Yes&rdquo; to Designate the same GPO for RX?</p>
    <p>[X] Yes</p>
    <p>[ ] No</p>
    <p>[ ] No RX Required</p>
    <p>I confirm I am the am the authorized signatory for the facilities listed above and that each such facility
      designates the Primary GPO referenced above as its primary GPO affiliation of record as of the Effective Date
      listed above. This primary GPO designation supersedes all prior declarations and will remain in effect until any
      future written designation form is submitted.</p>
    <p>[x] I Authorize</p>
    <p>[ ] I Do Not Authorize</p>
    <p>[ ] I Need More Information</p>
    <p>Authorized Signatory Full Name:</p>
    <p>Authorized Signatory Full Email:</p>
    <p>Signature &amp; Date:</p>
    <hr />
    <p><strong>McKesson Primary GPO (Group Purchasing Organization)</strong></p>
    <p><strong>Designation Form</strong></p>
    <p>Request Date:</p>
    <p>Customer Account Name:</p>
    <p>McKesson Bill to Account Number(s):</p>
    <p>Any Ship To Account Under the Bill To(s) will be included in the Designation.</p>
    <p> </p>
    <p>Any Exclusions/Exception to the Above Account(s) Designations:</p>
    <p>Please Enter McKesson Account Number(s) separated by commas</p>
    <p> </p>
    <p>Please Designate your Preferred / Primary GPO for the above Account(s)</p>
    <p>HealthTrust (HPG): </p>
    <p>HealthTrust GPOID(S):</p>
    <p>Please Check &ldquo;Yes&rdquo; to Designate the same GPO for RX?</p>
    <p>[X] Yes</p>
    <p>[ ] No</p>
    <p>[ ] No RX Required</p>
    <p>I confirm I am the am the authorized signatory for the facilities listed above and that each such facility
      designates the Primary GPO referenced above as its primary GPO affiliation of record with McKesson Medical
      Surgical as of the Effective Date listed above. This primary GPO designation supersedes all prior declarations and
      will remain in effect until any future written designation form is submitted by the customer to replace this
      designation.</p>
    <p>[x] I Authorize</p>
    <p>[ ] I Do Not Authorize</p>
    <p>[ ] I Need More Information</p>
    <p>Authorized Signatory Full Name:</p>
    <p>Authorized Signatory Full Email:</p>
    <p>Signature &amp; Date: </p>
    <hr />
    <p><strong>MANUFACTURER/SUPPLIER GPO NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust </p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that</p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust Agreement(s) for products and services covered under
      group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as a member of HealthTrust. Pursuant to this
      declaration, all sales under contract must be reported to HealthTrust. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>
    <p>Authorized Distributor(s): ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: ________________________________________</p>
    <p>Signature &amp; Date: _______________________________________</p>
    <hr />
    <p><strong>MANUFACTURER/SUPPLIER GPO NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust LLC</p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that </p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust LLC Agreement(s) for products and services covered under
      group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as a member of HealthTrust LLC. Pursuant to this
      declaration, all sales under contract must be reported to HealthTrust LLC. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust LLC GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>
    <p>Authorized Distributor(s): ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: ________________________________________</p>
    <p>Signature &amp; Date: ________________________________________</p>
    <hr />
    <p><strong>PHARMACY PROGRAM NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust</p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that </p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust Pharmacy Agreement(s) for products and services covered
      under group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as member of HealthTrust. Pursuant to this declaration,
      all sales under contract must be reported to HealthTrust. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>
    <p>Authorized Distributor(s): ________________________________________</p>
    <p>DEA or HIN #: ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: _______________________________________</p>
    <p>Signature &amp; Date: ________________________________________</p>
    <hr />
    <p><strong>PHARMACY PROGRAM NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust LLC</p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that </p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust LLC Pharmacy Agreement(s) for products and services
      covered under group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as member of HealthTrust LLC. Pursuant to this
      declaration, all sales under contract must be reported to HealthTrust LLC. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust LLC GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>

    <p><strong>For AdvantageTrust:</strong></p>
    <img src="assets/img/signature.png" alt="Signature" style="width: 100px; height: auto;">
    <p><strong>Zachary Sikes</strong></p>
    <p><strong>Senior Vice President, AdvantageTrust</strong></p>

    <p>Authorized Distributor(s): ________________________________________</p>
    <p>DEA or HIN #: ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: ________________________________________</p>
    <p>Signature &amp; Date: ________________________________________</p>

  </div>
  <div *ngSwitchCase="config.ampThemeID" class="tos-box">
    <h3 class="tos-header">Our Terms of Service for the Service</h3>
    <div>
      <p>AMP utilizes HealthTrust, LLC and Purchase Clinic technology to help administer Access Medical Purchasing, a
        member benefit provided through the American Society of Plastic Surgeons.</p>
    </div>
    <p>Last Updated November 1, 2020</p>
    <p>***IMPORTANT, PLEASE READ THESE ONLINE TERMS OF SERVICE CAREFULLY. HEALTHTRUST, LLC IS THE ADMINISTRATOR OF THIS
      PROGRAM.</p>
    <p>Purchase Clinic accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract
      portfolio. In addition, leverages HealthTrust, LLC and Purchase Clinic technology in the administration of the
      program</p>
    <p>HealthTrust, LLC, a Tennessee limited liability company doing business as HealthTrust.com
      (&ldquo;<strong>HealthTrust</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>our</strong>&rdquo;
      or &ldquo;<strong>us</strong>&rdquo;), permits eligible companies that have completed their online registration to
      access and use the Platform available through websites under the control of HealthTrust (the
      &ldquo;<strong>Websites</strong>&rdquo;) to complete certain GPO-related transactions (the
      &ldquo;<strong>Service</strong>&rdquo;), conditioned on acceptance of these Terms of Service (&ldquo;<strong>Terms
        of Service</strong>&rdquo;).</p>
    <p><strong>Note</strong>: These Terms of Service include a Disclaimer of warranties by HealthTrust (Section 8),
      Limitations on Liability and Remedies (Sections 9-10), Indeminication (Section 11), a dispute resolution provision
      (Section 13) that affect your rights with respect to the Service. Please review those Sections (and all other
      terms) carefully.</p>
    <p><strong>1. Acceptance of Terms of Service</strong></p>
    <p>These Terms of Service constitute a legal agreement between HealthTrust, the eligible corporation, limited
      liability company or other entity that elects to participate in the Service, whether as an entity that operates a
      medical practice, medical service, health and human service or other eligible service that has entered into our
      Participation Agreement (as defined below) and remains a member of our group purchasing organization (GPO) or an
      affiliated GPO (a &ldquo;<strong>Member</strong>&rdquo;) and each employee or representative of each Member who is
      issued a user name and password (collectively, &ldquo;<strong>Authorized User</strong>,&rdquo;
      &ldquo;<strong>you</strong>&rdquo; or &ldquo;<strong>your</strong>&rdquo;). Member is solely responsible for the
      acts and omissions of its Authorized Users and of any individual using the user name and password of its
      Authorized Users. As used herein, the terms &ldquo;you,&rdquo; &ldquo;your&rdquo; and words of similar import mean
      both Member in its legal capacity and the Authorized User in his or her individual capacity.</p>
    <p><strong>If you are eligible to be a Member or Authorized User and desire to obtain a license to access and use
        the Service, you will be required to demonstrate your agreement to these Terms of Service by reviewing these
        Terms of Service and by indicating your acceptance as part of the registration process. By indicating your
        acceptance, you acknowledge that you have read, understand and agree to be bound by these Terms of Service. If
        you do not agree to be bound, you should not sign the &ldquo;Signature&rdquo; box, and you will not be provided
        access to the Service.</strong></p>
    <p>These Terms of Service include a disclaimer of warranties, exclusive remedies and a disclaimer of liability, as
      well as indemnification by you, in Sections 8, 9, 10 and 11. Please review those sections (and all other terms)
      carefully. In addition, the Terms of Use for the Website contain additional restrictions on your use of the
      Website; such terms are hereby incorporated by reference. Please review those sections (and all other terms)
      carefully.</p>
    <p>Please also review our <strong>Privacy Policy http://purchaseclinic.com/privacy-policy/</strong>, which contains
      important information regarding the collection, use, disclosure and protection of your information, and our
      <strong>Terms of Use http://purchaseclinic.com/terms-of-use/</strong>, which sets forth the terms governing use of
      the Websites. Your agreement to these Terms of Service includes your agreement to our Privacy Policy and the Terms
      of Use. If there is a conflict between these Terms of Service, the Terms of Use and the Privacy Policy, the
      Privacy Policy shall have precedence with respect to the subject matter covered by it, the Terms of Use shall have
      precedence over general use of the Websites (as a visitor to the Websites) and otherwise these Terms of Service
      shall have precedence. If there is a conflict between these Terms of Service and the Participation Agreement, the
      Participation Agreement will control.
    </p>
    <p><strong>NOTE THAT</strong>, HealthTrust may make changes to the Service or these Terms of Service at any time. We
      encourage you to review our Websites and these Terms of Service regularly for any such changes. Your continued
      access to or use of the Service shall be deemed your acceptance of these changes and the reasonableness of these
      standards for notice.</p>
    <p><strong>2. Eligibility to Use the Service; Registration.</strong></p>
    <p>The Service is limited to corporations, limited liability companies and other legal entities organized under the
      laws of a State in the USA, the employees and authorized representatives designated as authorized users by such
      legal entities and individuals resident in the United States that are eligible to and have entered into a
      Participation Agreement that has not been terminated. If You currently are a committed member of a third party
      group purchasing organization and/or obligated by contract to a third party to purchase exclusively from that
      organization, you are not eligible to become an Authorized User. </p>
    <p>If you are eligible to become an Authorized User, you or your authorized representatives must set up an
      Authorized User account by completing the registration process prior to first using the Service, and each of your
      Authorized Users must also agree to these Terms of Service. You represent and warrant that the person establishing
      the account for each Authorized User is authorized to bind Authorized User to these Terms of Service. As part of
      the registration process, you must provide HealthTrust with complete and accurate information for each Authorized
      User, as prompted by the applicable registration form, including a valid email address. Each individual Authorized
      User will also choose a password and a user name. You agree that all information supplied by you in establishing
      your account(s) is accurate and complete, that you will maintain and promptly update the information, and that you
      consent to the storage of your information and content in the Service. If HealthTrust has reasonable grounds to
      suspect that your information is inaccurate or incomplete, HealthTrust may suspend or terminate your account(s),
      disable your password and refuse any and all current and future use of the Service, without prior notice.</p>
    <p>When you have completed the registration process, you will be authorized to use the Service until termination.
      Each Authorized User is entirely responsible for maintaining the confidentiality of user names and passwords. You
      agree to immediately notify HealthTrust of any known or suspected unauthorized use of your passwords, user names
      or accounts or any other breach of security. To the maximum extent permitted by applicable law, HealthTrust will
      not be liable for any loss that you may incur as a result of someone else using your passwords, user names or
      accounts, either with or without your knowledge, or for any inaccuracies or omissions in your data. However, you
      could be held liable for losses incurred by HealthTrust or a third party due to someone else using your account,
      user name or password.</p>
    <p><strong>3. Permitted Use of the Service</strong></p>
    <p>If you eligible to become an Authorized User, have agreed to these Terms of Service and your rights have not been
      terminated, HealthTrust will provide you access to the Service. You agree to access and use the Service, to the
      extent permitted by the functionality of the Websites, solely for the purposes of (a) accessing the HealthTrust
      platform (the &ldquo;<strong>Platform</strong>&rdquo;) in order to complete the GPO-related transactions permitted
      by the Websites, (b) accessing HealthTrust&rsquo;s tools, interfaces, templates, designs, guidelines and other
      content incorporated into the Platform (&ldquo;<strong>HealthTrust Content</strong>&rdquo;) and (c) tracking your
      account. You understand that your access rights are personal, nonexclusive and nontransferable, that your rights
      may be terminated by HealthTrust if you do not abide by these Terms of Service and that you may have liability to
      HealthTrust and third parties if you misuse the Service.</p>
    <p><strong>4. Entering into Agreements; Transactions</strong></p>
    <p><strong>Participation Agreements</strong></p>
    <p>Authorized Users that are eligible to do so must enter into GPO participation agreements
      (&ldquo;<strong>Participation Agreements</strong>&rdquo;) by completing the registration process made available
      through the Websites and signing the &ldquo;Signature&rdquo; box or giving any other required form of assent, at
      which time the Authorized User will be a &ldquo;Member&rdquo; and subject to the terms and conditions of the
      applicable Participation Agreement(s). The registration process may permit eligible entities and individuals to
      become Authorized Users and Members through a single transaction, in which case by signing the
      &ldquo;Signature&rdquo; box (or giving any other required form of assent), you agree both to these Terms of
      Service and to the terms of the applicable Participation Agreement.</p>
    <p>If you are Member and wish to do so, you may access contract pricing for goods and services from third-party
      vendors that are made available to you through your Participation Agreement by clicking on a vendor&rsquo;s link
      provided through the Websites. If you click a vendor&rsquo;s link provided through the Websites to make such a
      purchase from a vendor, you will leave the Websites, and any purchase you make will be subject to the terms and
      conditions of the vendor offering such goods and services, including the terms of use, terms of service, privacy
      policy and other such terms of the vendor&rsquo;s websites. Such terms and conditions may be different from the
      terms and conditions of these Websites.</p>
    <p><strong>Members acknowledge and agree that transactions for the purchase of goods and services from vendors are
        agreements solely between the Member and the vendor of those goods and services and, to the greatest extent
        permitted by applicable law, Members disclaim all liability against HealthTrust and its affiliates for any loss
        or claim arising out of any such transaction. HealthTrust and its affiliates make no representation or warranty
        about vendors whose website links are made available through the Websites or about the goods and services
        offered by such vendors</strong>.</p>
    <p><strong>Compliance</strong></p>
    <p>Authorized User is solely responsible for complying with all laws applicable to its use of the Service.
      Authorized User agrees that it shall not make any statements in connection with its use of the Service that is
      false or misleading. Authorized User is solely confirming its eligibility to become an Authoritized User and
      access the Service, and is solely responsible for complying with all applicable terms and conditions imposed by
      third-party social networking services used in connection with the Service.</p>
    <p><strong>Reporting Fraudulent Transactions</strong></p>
    <p>If you believe someone has fraudulently taken actions under your account, you must notify HealthTrust immediately
      at <u>support@purchaseclinic.com</u></p>
    <p><strong>Authorized User Content</strong></p>
    <p>If permitted by the functionality of the Websites, you may upload content, logos and designs relating to your
      business and incorporate such content (&ldquo;<strong>Authorized User Content</strong>&rdquo;) into the Platform.
      You hereby grant us and others, including third parties, a right and license to use, copy, host, display modify
      and create derivative works of all Authorized User Content you upload into the Service for the purposes of making
      the Service available, for the performance of our obligations under these Terms of Service and for
      HealthTrust&rsquo;s marketing purposes (as described in Section 14 below). You represent and warrant (a) that you
      have all necessary rights and licenses to grant the rights granted herein and for us and our vendors to use and
      display all Authorized User Content as contemplated herein, (b) that the Authorized User Content does not infringe
      upon, violate or misappropriate the intellectual property rights of any third parties, (c) that the Authorized
      User Content is not libelous, defamatory, offensive, insulting, derogatory or likely to bring HealthTrust into
      disrepute, and (d) that the Authorized User Content does not include any material or information that is subject
      to a third party&rsquo;s right of privacy or that, if disclosed to third parties, would violate or breach any
      applicable law, rule or regulation. Without limiting the foregoing, you represent and warrant that the Authorized
      User Content you upload into the Service will not include any Social Security numbers, personal health information
      or non-public financial information. As between you and HealthTrust, you retain ownership of Authorized User
      Content, subject to the limited rights granted herein.</p>
    <p><strong>5. Other Restrictions</strong></p>
    <p>HealthTrust reserves all rights in the Service not granted in these Terms of Service. Without limiting the
      foregoing, unless expressly permitted hereunder, by using the Service you agree not to:</p>
    <p>
    <ol>
      <li>translate, modify or create derivative works of the Service;</li>
      <li>upload, download, recreate, display, perform, post, reproduce or copy the Service, except to the extent
        permitted herein;</li>
      <li>disclose, publish, distribute, sell, assign, lease, sublicense or transfer the Service;</li>
      <li>attempt to derive the source code, source files or structure of the software contained in the Service by
        reverse engineering, disassembly, decompilation or any other means;</li>
      <li>use the Service to create a service bureau or for any other use involving processing of data for other persons
        or entities;</li>
      <li>use the Service except in accordance with all applicable law;</li>
      <li>introduce into the Service any &ldquo;malware,&rdquo; such as, but not limited to, viruses, worms and Trojan
        Horses;</li>
      <li>use deep-links, page scrapes, web crawlers, web robots, spiders, wanderers, web scutters, ants, automatic
        indexers, bots, worms, or other such devices, or programs, algorithms or methodologies which do the same things
        in connection with the Service, or use other automated processes to access or use the Service;</li>
      <li>attempt to access the accounts of any user of the Service other than your own account;</li>
      <li>post, transmit or link from any unlawful, infringing, misleading, deceptive, threatening, libelous,
        defamatory, plagiarized, fraudulent, harassing, obscene, discriminatory, inflammatory, pornographic or profane
        material, spam or any material that could constitute or encourage conduct that would be considered a criminal
        offense, give rise to civil liability, or would otherwise violate applicable law;</li>
      <li>use the Service in any manner that could damage, disable, undermine, overburden or impair the Service or the
        servers on which it runs or interfere with any other party&rsquo;s use of the Service;</li>
      <li>obtain or attempt to obtain any content through any means not intentionally made available or provided for
        through the Service; or</li>
      <li>use any of the logos, trademarks, service marks or other indicators of origin appearing on the Service.</li>
    </ol>
    <strong>6. Copyrights and Other Proprietary Rights</strong>
    <p>Authorized User acknowledges and agrees that HealthTrust and its licensors retain all ownership rights in the
      Service including the Websites, all HealthTrust software technology that is incorporated into or made available
      through the Websites, any downloadable software or related technical information for Platform integration
      (including, if applicable, application program interfaces) that is made available through the Websites and all
      modifications and improvements thereto (&ldquo;<strong>HealthTrust Technology</strong>&rdquo;) and that you do not
      receive any ownership rights or license rights, except as set forth herein, by accessing or using the Service or
      consenting to these Terms of Service. The entire content of the Service, the Websites and the HealthTrust
      Technology,<strong> </strong>including but not limited to text, design, software, photography, video, graphics,
      music, sound, information and the selection, coordination, arrangement, and enhancement thereof, is protected
      under the copyright laws of the United States, international treaties and other intellectual property laws
      (including without limitation the copyright in the selection, coordination, arrangement and enhancement of all
      content).</p>
    <p><strong>7. Confidential Information</strong></p>
    <p><strong>Definition</strong></p>
    <p>&ldquo;<strong>Confidential Information</strong>&rdquo; means information relating to the products, services or
      business affairs of HealthTrust which is of a proprietary or confidential nature, whether communicated orally or
      in writing, including, but not limited to, pricing information, print product printing volumes, the HealthTrust
      Technology, and HealthTrust&rsquo;s concepts, techniques, processes, designs, documentation, flow-charts,
      diagrams, instructions, computer programs, technical know-how, information and trade secrets disclosed by
      HealthTrust to Authorized Users under this Agreement (each, a &ldquo;<strong>Recipient</strong>&rdquo;).
      Confidential Information shall also include any information of a confidential nature concerning
      HealthTrust&rsquo;s financial affairs or business and any information HealthTrust has received from others which
      HealthTrust is obligated to treat as confidential or proprietary.</p>
    <p><strong>No Unauthorized Disclosure/Use</strong></p>
    <p>The Recipient acknowledges that irreparable injury and damage will result from disclosure of the Confidential
      Information to unauthorized third parties or from utilization of the Confidential Information for purposes other
      than those connected with the business relationship of the parties hereto. The Recipient shall not, without the
      prior written consent of HealthTrust, disclose any Confidential Information to any third party other than
      Authorized User&rsquo;s employees and personnel who have a need to know and who are bound by obligations of
      confidentiality consistent with this Section 7. The Recipient shall not use the Confidential Information except to
      perform its obligations and exercise its rights under these Terms of Service. The Recipient shall not be in breach
      of this section if disclosure of Confidential Information is made pursuant to subpoena or other compulsory
      judicial process, the Recipient promptly notifies HealthTrust of such subpoena or other compulsory process, and
      provides reasonable assistance at HealthTrust&rsquo;s request and expense so that HealthTrust may seek a
      protective order or take such other action it deems necessary to protect its interests.</p>
    <p><strong>Protection of Confidential Information</strong></p>
    <p>The Recipient agrees to take all necessary security precautions to protect the Confidential Information from
      unauthorized disclosure, including, without limitation, restricting access thereto and protecting documents
      containing Confidential Information from theft and from the unauthorized duplication or discovery of their
      contents.</p>
    <p><strong>Return of Materials</strong></p>
    <p>At any time upon HealthTrust&rsquo;s request, the Recipient will promptly return to HealthTrust all written
      material, in whatever form or media, containing or reflecting any Confidential Information of HealthTrust and will
      not retain any copies, extracts, or other reproductions, in whole or in part, of such written material. All
      documents, memoranda, notes, and other writings whatsoever, in whatever form or media, (including all copies,
      extracts, or other reproductions) prepared by or on behalf of the Recipient that are based on the information
      contained in the Confidential Information of HealthTrust will be destroyed, and such destruction will be certified
      in writing to HealthTrust by the Recipient. The return of such material will not relieve the Recipient&rsquo;s
      obligation of confidentiality or other obligations hereunder.</p>
    <p><strong>8. Disclaimer</strong></p>
    <p><strong>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE SERVICE IS
        PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; WITHOUT ANY WARRANTIES OF ANY KIND, AND
        PURCHASECLINIC AND ITS LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION,
        ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND
        WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE AND USAGE OF TRADE. YOUR USE OF THE SERVICE IS
        SOLELY AT YOUR OWN RISK. FURTHERMORE, PURCHASECLINIC AND ITS LICENSORS AND VENDORS DO NOT WARRANT THAT THE
        SERVICE WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE IS FREE OF VIRUSES
        OR OTHER HARMFUL COMPONENTS OR CODE OR THAT THE USE OF THE SERVICE WILL COMPLY WITH THE RULES OF ANY THIRD PARTY
        SOCIAL NETWORKING WEBSITE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS
        ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
        SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</strong></p>
    <p><strong>9. Exclusive Remedy</strong></p>
    <p>If you are dissatisfied with the Service (including without limitation these Terms of Service), you acknowledge
      and agree that your <strong>sole and exclusive remedy</strong> is to discontinue using the Service.</p>
    <p><strong>10. Limitations on Liability</strong></p>
    <p><strong>NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS OF SERVICE OR OTHERWISE, TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, PURCHASECLINIC AND ITS VENDORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY LOST OR
        CORRUPTED DATA OR CONTENT, ANY ERRORS CAUSED BY THE TOOLS AVAILABLE THROUGH THE WEBSITE, ANY TERMINATION,
        SUSPENSION OR OTHER LOSS OF YOUR OR A THIRD PARTY&rsquo;S SOCIAL NETWORKING ACCOUNT OR ANY SPECIAL, INDIRECT,
        INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF USE OF THE SERVICE OR ANY SUBJECT
        MATTER OF THESE TERMS OF SERVICE, EVEN IF PURCHASECLINIC HAS BEEN ADVISED OF OR COULD HAVE FORESEEN THE
        POSSIBILITY OF SUCH DAMAGES UNDER ANY THEORY OF LIABILITY. IN NO EVENT WILL PURCHASECLINIC HAVE ANY LIABILITY TO
        YOU OR ANY THIRD PARTY ARISING FROM YOUR USE OF THE SERVICE WHICH IN THE AGGREGATE IS MORE THAN ONE HUNDRED
        DOLLARS ($100.00).</strong></p>
    <p><strong>11. Indemnification</strong></p>
    <p>HealthTrust and its licensors, vendors, insurers, agents and commercial partners and our respective officers,
      directors, stockholders, employees and agents are, collectively, the &ldquo;<strong>HealthTrust
        Parties</strong>.&rdquo; You agree to indemnify and hold the HealthTrust Parties harmless from and against any
      and all claims, demands, liabilities, losses, damages, costs and expenses, including reasonable attorney&rsquo;s
      fees, that arise from any content or data you post, transmit or link from on the Websites or the Service, your
      misuse of the Websites or the Service, including, but not limited to, your violation of these Terms of Use or, if
      applicable to you, the Terms of Service, your violation of applicable law or your violation of any rights of any
      third party.</p>
    <p><strong>12. Termination</strong></p>
    <p>Subject to applicable law, HealthTrust reserves the right to terminate, suspend or deny, in its sole discretion,
      your access to all or any portion of the Service, without prior notice, if (a) Authorized User breaches or
      threatens to breach these Terms of Service, (b) Authorized User breaches or threatens to breach, as applicable,
      the applicable Participation Agreement, (c) an officer or executive of Authorized User inform us in writing that
      you are no longer authorized to use the Service, (d) if required to do so by operation of law, or (e) no activity
      occurs in your account or in the account of Authorized User for a period of ninety (90) days. In addition, if
      Authorized User is not a party to a then-current Participation Agreement, either party may terminate this
      agreement for convenience upon thirty (30) days&rsquo; prior written notice, provided that HealthTrust may give
      such notice by email using the email address you provided to us in registration. If either party terminates for
      convenience in accordance with the prior sentence prior to the termination of a Participation Agreement,
      termination shall be effective thirty (30) days after termination of the last effective Participation Agreement.
      Upon termination of this Agreement, you acknowledge and agree that HealthTrust may immediately deactivate or
      delete your account and all related data and files in your account, bar any further access to such data, files and
      the Service, and disable your password on termination of these Terms of Service. The following terms shall survive
      any termination of these Terms of Service: Sections 5, 6, 7, 9, 10, 11, 12, 13, 14 and 15.</p>
    <p><strong>13. Governing Law; Jurisdiction; Venue</strong></p>
    <p>HealthTrust controls the Service from its offices within the United States, and your data is stored in servers
      managed by HealthTrust in the United States. HealthTrust makes no representation that the Service is appropriate,
      may be downloaded, or is available for use outside the United States. Access to the Service where the content or
      access or use of the Service is illegal is prohibited. Those who choose to access and use the Service from outside
      the United States do so on their own initiative, at their own risk, and are responsible for compliance with
      applicable local laws and U.S. export and other applicable laws.</p>
    <p>The laws of the State of Tennessee will govern these Terms of Service, without reference to its conflicts of law
      principles, except that the federal law of the United States shall apply to questions regarding the validity,
      infringement or enforceability of U.S. federal patent, copyright and trademark rights relating in any way to these
      Terms of Service, or the Service. English is the only language applicable to these Terms of Service. You agree to
      submit to the exclusive jurisdiction of, and waive any venue objections and defenses of lack of personal
      jurisdiction against, the State and Federal courts located in Tennessee, except that you acknowledge that any
      breach of Sections 3, 5 and 7 cannot reasonably or adequately be compensated by damages in an action at law and
      that a breach or threatened breach of such provisions shall cause HealthTrust irreparable injury and damage, and
      HealthTrust shall be entitled, in addition to any other remedies it may have, to preliminary and permanent
      injunctive and other equitable relief to prevent or curtail any actual or threatened breach in any court of
      competent jurisdiction. Process may be served on you in the manner authorized by applicable law or court rule.</p>
    <p>ALL PARTIES TO ANY LITIGATION MUST BE INDIVIDUALLY NAMED. THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO
      BE LITIGATED ON A CLASS ACTION OR CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A PURPORTED
      REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER AUTHORIZED
      USERS, OR OTHER PERSONS.</p>
    <p><strong>14. Marketing by HealthTrust; Use of Authorized User Logos</strong></p>
    <p>Authorized User agrees that HealthTrust may list Authorized User&rsquo;s business identity in marketing materials
      and at marketing events (like trade shows) and to display Authorized User&rsquo;s logos (to the extent Authorized
      User is a legal entity and not an individual) on such lists, in such marketing materials and at such marketing
      events. HealthTrust agrees to follow Authorized User&rsquo;s reasonable instructions regarding the display of any
      Authorized User trademarks. With Authorized User&rsquo;s written permission, HealthTrust may prepare and publish
      case studies describing Authorized User&rsquo;s experience with the Platform for HealthTrust&rsquo;s marketing
      purposes. Unless Authorized User agrees in writing, such case studies will not identify Authorized User by name.
    </p>
    <p><strong>15. Miscellaneous Terms</strong></p>
    <p>If any provision of these Terms of Service is held to be unenforceable, that provision will be removed and the
      remaining provisions will remain in full force. The failure of either you or HealthTrust to require performance by
      the other party of any provision of these Terms of Service will not affect the right to require performance at any
      time in the future; nor will the waiver by either party of a breach of any provision be taken or held to be a
      waiver of the provision itself. These Terms of Service (including without limitation our Privacy Policy and Terms
      of Service) are the entire and exclusive agreement between you and HealthTrust with respect to your access and use
      of the Service.<strong> </strong>You may not assign this agreement or any portion thereof, in whole or in part,
      including by operation of law, without HealthTrust&rsquo;s prior written consent. HealthTrust may freely assign
      this agreement and delegate its obligations hereunder. Except as otherwise provided herein, any notice permitted
      or required to be given under these Terms of Service shall be deemed sufficient if given by registered or
      certified mail, postage prepaid, return receipt requested, by private courier service or by facsimile or email
      addressed to your address as provided upon your registration and/or to HealthTrust, attn.: Support at 10221 River
      Road #60053, Potomac, MD, 20854, or to such other addresses as the parties may designate by like notice from time
      to time. A notice so given shall be effective upon (a) receipt by the party to which the notice is given, or (b)
      on the fifth day following domestic mailing or the tenth day following international mailing, whichever occurs
      first. Any notice permitted under this Agreement to be given to HealthTrust via email will be effective only upon
      actual receipt by HealthTrust of an email message from an email address registered in connection with your
      account. Any delays in performance by HealthTrust under this Agreement will not be considered a breach of this
      Agreement. There are no third party beneficiaries to this Agreement.</p>
    <h3 class="tos-header">Participation Agreement</h3>

    <p>This Group Purchasing Organization Participation Agreement is entered into by and between the Plastic Surgery
      Practice Solutions, Inc., an affiliate of the American Society of Plastic Surgeons, doing business as Access
      Medical Purchasing (“AMP”) and “Member” indicated below. AMP and Member agree as follows:</p>

    Date: <strong>{{acceptanceDate}}</strong><br>
    <span *ngIf="ip">Your IP Address is <strong>{{ip}}</strong></span>

    <h2 style="margin: 1em 0 0.5em; padding: 0; font-size: 150%">Member</h2>

    <p>Organization’s Legal Name: <strong>{{loc.facility_name}}</strong><br />
    </p>

    <p>Street Address:<br>
      <span style="font-weight: bold">{{loc.address}} {{loc.address2}}<br> {{loc.city}}, {{loc.state}}
        {{loc.zip}}</span>
    </p>
    <p>Phone Number: <strong>{{user.phone}}</strong></p>

    <p><strong>Contact person</strong> to receive information about HealthTrust programs and savings opportunities:</p>
    <p>Name: <strong>{{user.fname}} {{user.lname}}</strong><br>
      Title: <strong>{{user.job_title}}</strong><br>
      E-Mail Address: <strong>{{user.email}}</strong><br>
      Phone Number: {{user.phone}}<br />
    </p>

    <div>
      <p><strong>Additional Locations:</strong> This Agreement shall apply collectively to all Member’s current and
        future locations. Current Location(s) are identified above and may be listed in Schedule 1. A list of all
        Member’s locations to which this Agreement applies is viewable in Member’s AMP account and is available upon
        request. Member warrants that it owns and/or manages locations and has legal authority to enter into this
        Agreement on their behalf. Member shall provide prompt written notice of all additions/changes to locations.</p>
      <p><strong>Exclusive Group Purchasing Agency:</strong> Member authorizes AMP as its exclusive group purchasing and
        contracting services agent to negotiate and enter into agreements with vendors in order to make agreements
        available to Member. Member authorizes AMP as its sole agent to negotiate and enter into affiliation agreements
        with other group purchasing organizations (“Affiliate GPOs”) and to enroll Member in Affiliate GPOs in order to
        make their agreements with vendors available to Member.</p>
      <p><strong>Vendor Agreements:</strong> The agreements made available to Member whether entered into by AMP or any
        Affiliate GPO shall be cumulatively referred to as “Vendor Agreements”. Member agrees to comply with the terms
        and conditions of any Vendor Agreement through which it chooses to make any purchase. </p>
      <p><strong>No Obligation to Purchase:</strong> Member is not obligated to make any purchase under this agreement.
      </p>
      <p><strong>Own Use:</strong> Member represents and warrants that any purchase made through any Vendor Agreement
        under this Agreement will be for Member’s “Own Use” and not for resale.</p>
      <p><strong>Confidentiality:</strong> Member agrees to keep strictly confidential and not disclose any Confidential
        Information (defined below) received from AMP and its Affiliate GPOs. “Confidential Information” means any trade
        secrets or proprietary information including but not limited to programs, services, systems, pricing, agreements
        or information technology shared with Member by AMP and its Affiliate GPOs.</p>
      <p><strong>Authority; Representations & Warranties:</strong> Member represents that execution of this agreement
        will not violate any agreements between member and any third party, including but not limited to other group
        purchasing organizations. AMP makes no express or implied representation or warranties under this agreement,
        including but not limited to any Vendor Agreement, as to the products or services offered thereunder, or their
        suitability for any purpose. Any analysis, pricing, or product information in any Vendor Agreement is provided
        as is with no guarantee of accuracy or completeness. </p>
      <p><strong>Limitation of Liability:</strong> Member acknowledges that member is solely responsible for deciding to
        enter this agreement and determining which if any products or services to purchase through the Vendor
        Agreements. Member acknowledges and agrees that AMP, and its respective officers, directors, shareholders,
        employees, agents and contractors shall not have any liability of any nature or kind whatsoever for any personal
        injury, property damage, lost profits, attorney’s fees, court costs or any other damages, legal, equitable or
        otherwise, caused either directly or indirectly by or related to the products or services offered or sold under
        the Vendor Agreements. </p>
      <p><strong>Term & Termination:</strong> The initial term of this Agreement will be for one year commencing on the
        date it is signed by AMP. This Agreement will renew annually for an additional one-year term upon expiration of
        the current term, unless either party gives to the other party written notice of its intent to terminate the
        Agreement not less than 60 days prior to the end of the current term. Either party may terminate this agreement
        for any reason by providing the other party 60 days’ prior written notice. </p>
      <p><strong>Notice:</strong> All notices, requests, demands and other communications hereunder shall be in writing
        and shall be duly given to any party, (a) upon delivery to the address of such party specified below if
        delivered in person or by courier; or (b) upon dispatch if transmitted by electronic mail to the following
        addresses:</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;If to AMP:<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Access Medical
        Purchasing<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;444 East Algonquin
        Road<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arlington Heights, Illinois
        60008<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ATTN: Kathy Feifar</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;E-mail
        Address:<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;amp@plasticsurgery.org</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;If to Member:<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{user.fname}}
        {{user.lname}}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{loc.address}}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{loc.city}},
        {{loc.state}} {{loc.zip}}</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;E-Mail Address:<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{user.email}}</p>
      <p>The parties may change the foregoing addresses and facsimile numbers through written notice to the other party
        as provided herein.</p>
      <p><strong>Complete Agreement:</strong> This Agreement sets forth all of the understandings of the parties with
        respect to the subject matter hereof, supersedes any prior, oral or written understandings and may not be
        altered, modified, or amended except by written instruments signed by both parties.</p>
      <p><strong>Governing Law:</strong> This Agreement shall be construed under and governed by the laws of the State
        of Illinois, without giving effect to its conflicts of laws principles.</p>
    </div>
  </div>
  <div *ngSwitchCase="config.capThemeID" class="tos-box">
    <h3 class="tos-header">Our Terms of Service for the Service</h3>
    <div>
      <p>CAP Purchasing Alliance (CPA) is provided as a free member benefit through membership in the Cooperative of
        American Physicians. CPA accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust
        contract portfolio. In addition, CPA leverages HealthTrust, LLC and Purchase Clinic technology in the
        administration of the program.</p>
    </div>
    <p>Last Updated November 1, 2020</p>
    <p>***IMPORTANT, PLEASE READ THESE ONLINE TERMS OF SERVICE CAREFULLY. HEALTHTRUST, LLC IS THEADMINISTRATOR OF THIS
      PROGRAM.</p>
    <p>HealthTrust, LLC, a Tennessee limited liability company doing business as HealthTrust.com
      (“<strong>HealthTrust</strong>,” “<strong>we</strong>,” “<strong>our</strong>” or “<strong>us</strong>”), permits
      eligible companies that have completed their online registration to access and use the Platform available through
      websites under the control of HealthTrust (the “<strong>Websites</strong>”) to complete certain GPO-related
      transactions (the “<strong>Service</strong>”), conditioned on acceptance of these Terms of Service (“<strong>Terms
        of Service</strong>”).
    </p>
    <p>
      <strong>Note</strong>: These Terms of Service include a Disclaimer of warranties by HealthTrust (Section 8),
      Limitations on Liability and Remedies (Sections 9-10), Indeminication (Section 11), a dispute resolution provision
      (Section 13) that affect your rights with respect to the Service. Please review those Sections (and all other
      terms) carefully.
    </p>
    <strong>1. Acceptance of Terms of Service</strong>
    <p>These Terms of Service constitute a legal agreement between HealthTrust, the eligible corporation, limited
      liability company or other entity that elects to participate in the Service, whether as an entity that operates a
      medical practice, medical service, health and human service or other eligible service that has entered into our
      Participation Agreement (as defined below) and remains a member of our group purchasing organization (GPO) or an
      affiliated GPO (a “<strong>Member</strong>”) and each employee or representative of each Member who is issued a
      user name and password (collectively, “<strong>Authorized User</strong>,” “<strong>you</strong>” or
      “<strong>your</strong>”). Member is solely responsible for the acts and omissions of its Authorized Users and of
      any individual using the user name and password of its Authorized Users. As used herein, the terms “you,” “your”
      and words of similar import mean both Member in its legal capacity and the Authorized User in his or her
      individual capacity.
    </p>

    <p>
      <strong>If you are eligible to be a Member or Authorized User and desire to obtain a license to access and use the
        Service, you will be required to demonstrate your agreement to these Terms of Service by reviewing these Terms
        of Service and by indicating your acceptance as part of the registration process. By indicating your acceptance,
        you acknowledge that you have read, understand and agree to be bound by these Terms of Service. If you do not
        agree to be bound, you should not sign the “Signature” box, and you will not be provided access to the
        Service.</strong>
    </p>

    <p>These Terms of Service include a disclaimer of warranties, exclusive remedies and a disclaimer of liability, as
      well as indemnification by you, in Sections 8, 9, 10 and 11. Please review those sections (and all other terms)
      carefully. In addition, the Terms of Use for the Website contain additional restrictions on your use of the
      Website; such terms are hereby incorporated by reference. Please review those sections (and all other terms)
      carefully.</p>

    <p>Please also review our <strong>Privacy Policy http://purchaseclinic.com/privacy-policy/</strong>, which contains
      important information regarding the collection, use, disclosure and protection of your information, and our
      <strong>Terms of Use http://purchaseclinic.com/terms-of-use/</strong>, which sets forth the terms governing use of
      the Websites. Your agreement to these Terms of Service includes your agreement to our Privacy Policy and the Terms
      of Use. If there is a conflict between these Terms of Service, the Terms of Use and the Privacy Policy, the
      Privacy Policy shall have precedence with respect to the subject matter covered by it, the Terms of Use shall have
      precedence over general use of the Websites (as a visitor to the Websites) and otherwise these Terms of Service
      shall have precedence. If there is a conflict between these Terms of Service and the Participation Agreement, the
      Participation Agreement will control.
    </p>

    <p>
      <strong>NOTE THAT</strong>, HealthTrust may make changes to the Service or these Terms of Service at any time. We
      encourage you to review our Websites and these Terms of Service regularly for any such changes. Your continued
      access to or use of the Service shall be deemed your acceptance of these changes and the reasonableness of these
      standards for notice.
    </p>

    <p><strong>2. Eligibility to Use the Service; Registration.</strong></p>

    <p>The Service is limited to corporations, limited liability companies and other legal entities organized under the
      laws of a State in the USA, the employees and authorized representatives designated as authorized users by such
      legal entities and individuals resident in the United States that are eligible to and have entered into a
      Participation Agreement that has not been terminated. If You currently are a committed member of a third party
      group purchasing organization and/or obligated by contract to a third party to purchase exclusively from that
      organization, you are not eligible to become an Authorized User.В&nbsp;</p>

    <p>If you are eligible to become an Authorized User, you or your authorized representatives must set up an
      Authorized User account by completing the registration process prior to first using the Service, and each of your
      Authorized Users must also agree to these Terms of Service. You represent and warrant that the person establishing
      the account for each Authorized User is authorized to bind Authorized User to these Terms of Service. As part of
      the registration process, you must provide HealthTrust with complete and accurate information for each Authorized
      User, as prompted by the applicable registration form, including a valid email address. Each individual Authorized
      User will also choose a password and a user name. You agree that all information supplied by you in establishing
      your account(s) is accurate and complete, that you will maintain and promptly update the information, and that you
      consent to the storage of your information and content in the Service. If HealthTrust has reasonable grounds to
      suspect that your information is inaccurate or incomplete, HealthTrust may suspend or terminate your account(s),
      disable your password and refuse any and all current and future use of the Service, without prior notice.</p>

    <p>When you have completed the registration process, you will be authorized to use the Service until termination.
      Each Authorized User is entirely responsible for maintaining the confidentiality of user names and passwords. You
      agree to immediately notify HealthTrust of any known or suspected unauthorized use of your passwords, user names
      or accounts or any other breach of security. To the maximum extent permitted by applicable law, HealthTrust will
      not be liable for any loss that you may incur as a result of someone else using your passwords, user names or
      accounts, either with or without your knowledge, or for any inaccuracies or omissions in your data. However, you
      could be held liable for losses incurred by HealthTrust or a third party due to someone else using your account,
      user name or password.</p>

    <p><strong>3. Permitted Use of the Service</strong></p>

    <p>If you eligible to become an Authorized User, have agreed to these Terms of Service and your rights have not been
      terminated, HealthTrust will provide you access to the Service. You agree to access and use the Service, to the
      extent permitted by the functionality of the Websites, solely for the purposes of (a) accessing the HealthTrust
      platform (the “<strong>Platform</strong>”) in order to complete the GPO-related transactions permitted by the
      Websites, (b) accessing HealthTrust’s tools, interfaces, templates, designs, guidelines and other content
      incorporated into the Platform (“<strong>HealthTrust Content</strong>”) and (c) tracking your account. You
      understand that your access rights are personal, nonexclusive and nontransferable, that your rights may be
      terminated by HealthTrust if you do not abide by these Terms of Service and that you may have liability to
      HealthTrust and third parties if you misuse the Service.
    </p>

    <p><strong>4. Entering into Agreements; Transactions</strong></p>

    <p><strong>Participation Agreements</strong></p>

    <p>Authorized Users that are eligible to do so must enter into GPO participation agreements (“<strong>Participation
        Agreements</strong>”) by completing the registration process made available through the Websites and signing the
      “Signature” box or giving any other required form of assent, at which time the Authorized User will be a “Member”
      and subject to the terms and conditions of the applicable Participation Agreement(s). The registration process may
      permit eligible entities and individuals to become Authorized Users and Members through a single transaction, in
      which case by signing the “Signature” box (or giving any other required form of assent), you agree both to these
      Terms of Service and to the terms of the applicable Participation Agreement.
    </p>

    <p>If you are Member and wish to do so, you may access contract pricing for goods and services from third-party
      vendors that are made available to you through your Participation Agreement by clicking on a vendor’s link
      provided through the Websites. If you click a vendor’s link provided through the Websites to make such a purchase
      from a vendor, you will leave the Websites, and any purchase you make will be subject to the terms and conditions
      of the vendor offering such goods and services, including the terms of use, terms of service, privacy policy and
      other such terms of the vendor’s websites. Such terms and conditions may be different from the terms and
      conditions of these Websites.</p>

    <p>
      <strong>Members acknowledge and agree that transactions for the purchase of goods and services from vendors are
        agreements solely between the Member and the vendor of those goods and services and, to the greatest extent
        permitted by applicable law, Members disclaim all liability against HealthTrust and its affiliates for any loss
        or claim arising out of any such transaction. HealthTrust and its affiliates make no representation or warranty
        about vendors whose website links are made available through the Websites or about the goods and services
        offered by such vendors</strong>.
    </p>

    <p><strong>Compliance</strong></p>

    <p>Authorized User is solely responsible for complying with all laws applicable to its use of the Service.
      Authorized User agrees that it shall not make any statements in connection with its use of the Service that is
      false or misleading. Authorized User is solely confirming its eligibility to become an Authoritized User and
      access the Service, and is solely responsible for complying with all applicable terms and conditions imposed by
      third-party social networking services used in connection with the Service.</p>

    <p><strong>Reporting Fraudulent Transactions</strong></p>

    <p>If you believe someone has fraudulently taken actions under your account, you must notify HealthTrust immediately
      at <u>support@purchaseclinic.com</u></p>

    <p><strong>Authorized User Content</strong></p>

    <p>If permitted by the functionality of the Websites, you may upload content, logos and designs relating to your
      business and incorporate such content (“<strong>Authorized User Content</strong>”) into the Platform. You hereby
      grant us and others, including third parties, a right and license to use, copy, host, display modify and create
      derivative works of all Authorized User Content you upload into the Service for the purposes of making the Service
      available, for the performance of our obligations under these Terms of Service and for HealthTrust’s marketing
      purposes (as described in Section 14 below). You represent and warrant (a) that you have all necessary rights and
      licenses to grant the rights granted herein and for us and our vendors to use and display all Authorized User
      Content as contemplated herein, (b) that the Authorized User Content does not infringe upon, violate or
      misappropriate the intellectual property rights of any third parties, (c) that the Authorized User Content is not
      libelous, defamatory, offensive, insulting, derogatory or likely to bring HealthTrust into disrepute, and (d) that
      the Authorized User Content does not include any material or information that is subject to a third party’s right
      of privacy or that, if disclosed to third parties, would violate or breach any applicable law, rule or regulation.
      Without limiting the foregoing, you represent and warrant that the Authorized User Content you upload into the
      Service will not include any Social Security numbers, personal health information or non-public financial
      information. As between you and HealthTrust, you retain ownership of Authorized User Content, subject to the
      limited rights granted herein.
    </p>

    <p><strong>5. Other Restrictions</strong></p>

    <p>HealthTrust reserves all rights in the Service not granted in these Terms of Service. Without limiting the
      foregoing, unless expressly permitted hereunder, by using the Service you agree not to:</p>

    <p></p>
    <ol>
      <li>translate, modify or create derivative works of the Service;</li>
      <li>upload, download, recreate, display, perform, post, reproduce or copy the Service, except to the extent
        permitted herein;</li>
      <li>disclose, publish, distribute, sell, assign, lease, sublicense or transfer the Service;</li>
      <li>attempt to derive the source code, source files or structure of the software contained in the Service by
        reverse engineering, disassembly, decompilation or any other means;</li>
      <li>use the Service to create a service bureau or for any other use involving processing of data for other persons
        or entities;</li>
      <li>use the Service except in accordance with all applicable law;</li>
      <li>introduce into the Service any “malware,” such as, but not limited to, viruses, worms and Trojan Horses;</li>
      <li>use deep-links, page scrapes, web crawlers, web robots, spiders, wanderers, web scutters, ants, automatic
        indexers, bots, worms, or other such devices, or programs, algorithms or methodologies which do the same things
        in connection with the Service, or use other automated processes to access or use the Service;</li>
      <li>attempt to access the accounts of any user of the Service other than your own account;</li>
      <li>post, transmit or link from any unlawful, infringing, misleading, deceptive, threatening, libelous,
        defamatory, plagiarized, fraudulent, harassing, obscene, discriminatory, inflammatory, pornographic or profane
        material, spam or any material that could constitute or encourage conduct that would be considered a criminal
        offense, give rise to civil liability, or would otherwise violate applicable law;</li>
      <li>use the Service in any manner that could damage, disable, undermine, overburden or impair the Service or the
        servers on which it runs or interfere with any other party’s use of the Service;</li>
      <li>obtain or attempt to obtain any content through any means not intentionally made available or provided for
        through the Service; or</li>
      <li>use any of the logos, trademarks, service marks or other indicators of origin appearing on the Service.</li>
    </ol>

    <strong>6. Copyrights and Other Proprietary Rights</strong>
    <p></p>

    <p>Authorized User acknowledges and agrees that HealthTrust and its licensors retain all ownership rights in the
      Service including the Websites, all HealthTrust software technology that is incorporated into or made available
      through the Websites, any downloadable software or related technical information for Platform integration
      (including, if applicable, application program interfaces) that is made available through the Websites and all
      modifications and improvements thereto (“<strong>HealthTrust Technology</strong>”) and that you do not receive any
      ownership rights or license rights, except as set forth herein, by accessing or using the Service or consenting to
      these Terms of Service. The entire content of the Service, the Websites and the HealthTrust Technology,<strong>
      </strong>including but not limited to text, design, software, photography, video, graphics, music, sound,
      information and the selection, coordination, arrangement, and enhancement thereof, is protected under the
      copyright laws of the United States, international treaties and other intellectual property laws (including
      without limitation the copyright in the selection, coordination, arrangement and enhancement of all content).
    </p>

    <p><strong>7. Confidential Information</strong></p>

    <p><strong>Definition</strong></p>

    <p>“<strong>Confidential Information</strong>” means information relating to the products, services or business
      affairs of HealthTrust which is of a proprietary or confidential nature, whether communicated orally or in
      writing, including, but not limited to, pricing information, print product printing volumes, the HealthTrust
      Technology, and HealthTrust’s concepts, techniques, processes, designs, documentation, flow-charts, diagrams,
      instructions, computer programs, technical know-how, information and trade secrets disclosed by HealthTrust to
      Authorized Users under this Agreement (each, a “<strong>Recipient</strong>”). Confidential Information shall also
      include any information of a confidential nature concerning HealthTrust’s financial affairs or business and any
      information HealthTrust has received from others which HealthTrust is obligated to treat as confidential or
      proprietary.
    </p>

    <p><strong>No Unauthorized Disclosure/Use</strong></p>

    <p>The Recipient acknowledges that irreparable injury and damage will result from disclosure of the Confidential
      Information to unauthorized third parties or from utilization of the Confidential Information for purposes other
      than those connected with the business relationship of the parties hereto. The Recipient shall not, without the
      prior written consent of HealthTrust, disclose any Confidential Information to any third party other than
      Authorized User’s employees and personnel who have a need to know and who are bound by obligations of
      confidentiality consistent with this Section 7. The Recipient shall not use the Confidential Information except to
      perform its obligations and exercise its rights under these Terms of Service. The Recipient shall not be in breach
      of this section if disclosure of Confidential Information is made pursuant to subpoena or other compulsory
      judicial process, the Recipient promptly notifies HealthTrust of such subpoena or other compulsory process, and
      provides reasonable assistance at HealthTrust’s request and expense so that HealthTrust may seek a protective
      order or take such other action it deems necessary to protect its interests.</p>

    <p><strong>Protection of Confidential Information</strong></p>

    <p>The Recipient agrees to take all necessary security precautions to protect the Confidential Information from
      unauthorized disclosure, including, without limitation, restricting access thereto and protecting documents
      containing Confidential Information from theft and from the unauthorized duplication or discovery of their
      contents.</p>

    <p><strong>Return of Materials</strong></p>

    <p>At any time upon HealthTrust’s request, the Recipient will promptly return to HealthTrust all written material,
      in whatever form or media, containing or reflecting any Confidential Information of HealthTrust and will not
      retain any copies, extracts, or other reproductions, in whole or in part, of such written material. All documents,
      memoranda, notes, and other writings whatsoever, in whatever form or media, (including all copies, extracts, or
      other reproductions) prepared by or on behalf of the Recipient that are based on the information contained in the
      Confidential Information of HealthTrust will be destroyed, and such destruction will be certified in writing to
      HealthTrust by the Recipient. The return of such material will not relieve the Recipient’s obligation of
      confidentiality or other obligations hereunder.</p>

    <p><strong>8. Disclaimer</strong></p>

    <p>
      <strong>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE SERVICE IS
        PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTIES OF ANY KIND, AND PURCHASECLINIC AND ITS LICENSORS
        DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND WARRANTIES ARISING FROM COURSE
        OF DEALING, COURSE OF PERFORMANCE AND USAGE OF TRADE. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.
        FURTHERMORE, PURCHASECLINIC AND ITS LICENSORS AND VENDORS DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED
        OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
        OR CODE OR THAT THE USE OF THE SERVICE WILL COMPLY WITH THE RULES OF ANY THIRD PARTY SOCIAL NETWORKING WEBSITE.
        ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION
        AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
        FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</strong>
    </p>

    <p><strong>9. Exclusive Remedy</strong></p>

    <p>If you are dissatisfied with the Service (including without limitation these Terms of Service), you acknowledge
      and agree that your <strong>sole and exclusive remedy</strong> is to discontinue using the Service.</p>

    <p><strong>10. Limitations on Liability</strong></p>

    <p>
      <strong>NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS OF SERVICE OR OTHERWISE, TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, PURCHASECLINIC AND ITS VENDORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY LOST OR
        CORRUPTED DATA OR CONTENT, ANY ERRORS CAUSED BY THE TOOLS AVAILABLE THROUGH THE WEBSITE, ANY TERMINATION,
        SUSPENSION OR OTHER LOSS OF YOUR OR A THIRD PARTY’S SOCIAL NETWORKING ACCOUNT OR ANY SPECIAL, INDIRECT,
        INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF USE OF THE SERVICE OR ANY SUBJECT
        MATTER OF THESE TERMS OF SERVICE, EVEN IF PURCHASECLINIC HAS BEEN ADVISED OF OR COULD HAVE FORESEEN THE
        POSSIBILITY OF SUCH DAMAGES UNDER ANY THEORY OF LIABILITY. IN NO EVENT WILL PURCHASECLINIC HAVE ANY LIABILITY TO
        YOU OR ANY THIRD PARTY ARISING FROM YOUR USE OF THE SERVICE WHICH IN THE AGGREGATE IS MORE THAN ONE HUNDRED
        DOLLARS ($100.00).</strong>
    </p>

    <p><strong>11. Indemnification</strong></p>

    <p>HealthTrust and its licensors, vendors, insurers, agents and commercial partners and our respective officers,
      directors, stockholders, employees and agents are, collectively, the “<strong>HealthTrust Parties</strong>.” You
      agree to indemnify and hold the HealthTrust Parties harmless from and against any and all claims, demands,
      liabilities, losses, damages, costs and expenses, including reasonable attorney’s fees, that arise from any
      content or data you post, transmit or link from on the Websites or the Service, your misuse of the Websites or the
      Service, including, but not limited to, your violation of these Terms of Use or, if applicable to you, the Terms
      of Service, your violation of applicable law or your violation of any rights of any third party.
    </p>

    <p><strong>12. Termination</strong></p>

    <p>Subject to applicable law, HealthTrust reserves the right to terminate, suspend or deny, in its sole discretion,
      your access to all or any portion of the Service, without prior notice, if (a) Authorized User breaches or
      threatens to breach these Terms of Service, (b) Authorized User breaches or threatens to breach, as applicable,
      the applicable Participation Agreement, (c) an officer or executive of Authorized User inform us in writing that
      you are no longer authorized to use the Service, (d) if required to do so by operation of law, or (e) no activity
      occurs in your account or in the account of Authorized User for a period of ninety (90) days. In addition, if
      Authorized User is not a party to a then-current Participation Agreement, either party may terminate this
      agreement for convenience upon thirty (30) days’ prior written notice, provided that HealthTrust may give such
      notice by email using the email address you provided to us in registration. If either party terminates for
      convenience in accordance with the prior sentence prior to the termination of a Participation Agreement,
      termination shall be effective thirty (30) days after termination of the last effective Participation Agreement.
      Upon termination of this Agreement, you acknowledge and agree that HealthTrust may immediately deactivate or
      delete your account and all related data and files in your account, bar any further access to such data, files and
      the Service, and disable your password on termination of these Terms of Service. The following terms shall survive
      any termination of these Terms of Service: Sections 5, 6, 7, 9, 10, 11, 12, 13, 14 and 15.</p>

    <p><strong>13. Governing Law; Jurisdiction; Venue</strong></p>

    <p>HealthTrust controls the Service from its offices within the United States, and your data is stored in servers
      managed by HealthTrust in the United States. HealthTrust makes no representation that the Service is appropriate,
      may be downloaded, or is available for use outside the United States. Access to the Service where the content or
      access or use of the Service is illegal is prohibited. Those who choose to access and use the Service from outside
      the United States do so on their own initiative, at their own risk, and are responsible for compliance with
      applicable local laws and U.S. export and other applicable laws.</p>

    <p>The laws of the State of Tennessee will govern these Terms of Service, without reference to its conflicts of law
      principles, except that the federal law of the United States shall apply to questions regarding the validity,
      infringement or enforceability of U.S. federal patent, copyright and trademark rights relating in any way to these
      Terms of Service, or the Service. English is the only language applicable to these Terms of Service. You agree to
      submit to the exclusive jurisdiction of, and waive any venue objections and defenses of lack of personal
      jurisdiction against, the State and Federal courts located in Tennessee, except that you acknowledge that any
      breach of Sections 3, 5 and 7 cannot reasonably or adequately be compensated by damages in an action at law and
      that a breach or threatened breach of such provisions shall cause HealthTrust irreparable injury and damage, and
      HealthTrust shall be entitled, in addition to any other remedies it may have, to preliminary and permanent
      injunctive and other equitable relief to prevent or curtail any actual or threatened breach in any court of
      competent jurisdiction. Process may be served on you in the manner authorized by applicable law or court rule.</p>

    <p>ALL PARTIES TO ANY LITIGATION MUST BE INDIVIDUALLY NAMED. THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO
      BE LITIGATED ON A CLASS ACTION OR CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A PURPORTED
      REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER AUTHORIZED
      USERS, OR OTHER PERSONS.</p>

    <p><strong>14. Marketing by HealthTrust; Use of Authorized User Logos</strong></p>

    <p>Authorized User agrees that HealthTrust may list Authorized User’s business identity in marketing materials and
      at marketing events (like trade shows) and to display Authorized User’s logos (to the extent Authorized User is a
      legal entity and not an individual) on such lists, in such marketing materials and at such marketing events.
      HealthTrust agrees to follow Authorized User’s reasonable instructions regarding the display of any Authorized
      User trademarks. With Authorized User’s written permission, HealthTrust may prepare and publish case studies
      describing Authorized User’s experience with the Platform for HealthTrust’s marketing purposes. Unless Authorized
      User agrees in writing, such case studies will not identify Authorized User by name.</p>

    <p><strong>15. Miscellaneous Terms</strong></p>

    <p>If any provision of these Terms of Service is held to be unenforceable, that provision will be removed and the
      remaining provisions will remain in full force. The failure of either you or HealthTrust to require performance by
      the other party of any provision of these Terms of Service will not affect the right to require performance at any
      time in the future; nor will the waiver by either party of a breach of any provision be taken or held to be a
      waiver of the provision itself. These Terms of Service (including without limitation our Privacy Policy and Terms
      of Service) are the entire and exclusive agreement between you and HealthTrust with respect to your access and use
      of the Service.<strong> </strong>You may not assign this agreement or any portion thereof, in whole or in part,
      including by operation of law, without HealthTrust’s prior written consent. HealthTrust may freely assign this
      agreement and delegate its obligations hereunder. Except as otherwise provided herein, any notice permitted or
      required to be given under these Terms of Service shall be deemed sufficient if given by registered or certified
      mail, postage prepaid, return receipt requested, by private courier service or by facsimile or email addressed to
      your address as provided upon your registration and/or to HealthTrust, attn.: Support at 10221 River Road #60053,
      Potomac, MD, 20854, or to such other addresses as the parties may designate by like notice from time to time. A
      notice so given shall be effective upon (a) receipt by the party to which the notice is given, or (b) on the fifth
      day following domestic mailing or the tenth day following international mailing, whichever occurs first. Any
      notice permitted under this Agreement to be given to HealthTrust via email will be effective only upon actual
      receipt by HealthTrust of an email message from an email address registered in connection with your account. Any
      delays in performance by HealthTrust under this Agreement will not be considered a breach of this Agreement. There
      are no third party beneficiaries to this Agreement.
    </p>

    <h3 class="tos-header">Participation Agreement</h3>

    <p>This Group Purchasing Organization Participation Agreement is entered into by and between HealthTrust, LLC, a
      Tennessee limited liability company (“HealthTrust”), and “Member” indicated below. HealthTrust and Member agree as
      follows:</p>

    Date: <strong>{{acceptanceDate}}</strong><br>
    <span *ngIf="ip">Your IP Address is <strong>{{ip}}</strong></span>

    <h2 style="margin: 1em 0 0.5em; padding: 0; font-size: 150%">Member</h2>

    <p>Organization’s Legal Name: <strong>{{loc.facility_name}}</strong><br />
    </p>

    <p>Street Address:<br>
      <span style="font-weight: bold">{{loc.address}} {{loc.address2}}<br> {{loc.city}}, {{loc.state}}
        {{loc.zip}}</span>
    </p>
    <p>Phone Number: <strong>{{user.phone}}</strong></p>

    <p><strong>Contact person</strong> to receive information about HealthTrust programs and savings opportunities:</p>
    <p>Name: <strong>{{user.fname}} {{user.lname}}</strong><br>
      Title: <strong>{{user.job_title}}</strong><br>
      E-Mail Address: <strong>{{user.email}}</strong><br>
    </p>

    <p>
      <strong>Additional Locations</strong>: This Agreement shall apply collectively to all Member’s current and future
      Locations. Current Location(s) are identified above and may be listed in Schedule 1. A list of all Member’s
      Locations to which this Agreement applies is viewable in Member’s Purchase Clinic account and is available upon
      request. Member warrants that it owns and/or manages Locations and has legal authority to enter into this
      Agreement on their behalf. Member shall provide prompt written notice of all additions/changes to Locations.
    </p>
    <p>
      <strong>Exclusive Group Purchasing Agency</strong>: Member authorizes HealthTrust as its exclusive group
      purchasing and contracting services agent to negotiate and enter into agreements with vendors in order to make
      agreements available to Member. Member authorizes HealthTrust as its sole agent to negotiate and enter into
      affiliation agreements with other group purchasing organizations (“Affiliate GPOs”) and to enroll Member in
      Affiliate GPOs in order to make their agreements with vendors available to Member.
    </p>
    <p>
      <strong>Vendor Agreements</strong>: The agreements made available to Member whether entered into by HealthTrust or
      any Affiliate GPO shall be cumulatively referred to as “Vendor Agreements”. Member agrees to comply with the terms
      and conditions of any Vendor Agreement through which it chooses to make any purchase.
    </p>
    <p><strong>NO OBLIGATION TO PURCHASE</strong>: MEMBER IS NOT OBLIGATED TO MAKE ANY PURCHASE UNDER THIS AGREEMENT.
    </p>
    <p><strong>Own Use</strong>: Member represents and warrants that any purchase made through any Vendor Agreement
      under this Agreement will be for Member’s “Own Use” and not for resale.</p>
    <p>
      <strong>Rebates</strong>: Any rebates due to Member based on Member’s purchases through any Vendor Agreement, if
      received by HealthTrust or its Affiliate GPOs, will be forwarded to Member at least quarterly. Member is solely
      responsible for reporting any such rebate as required by law.
    </p>
    <p>
      <strong>Administrative Fees</strong>: HealthTrust discloses to Member that it and its Affiliate GPOs receive
      administrative fees from contracted vendors based on purchases made by Member. The administrative fee varies
      according to each Vendor Agreement and is generally (3%) or less of the purchase price of the goods or services
      purchased by Member; HealthTrust or its Affiliate GPO will report to Member any administrative fee that is greater
      than three percent (3%). HealthTrust and its Affiliate GPOs will report to Member annually, and to the Secretary
      of the U.S. Department of Health and Human Services upon request, the fees received under each Vendor Agreement
      for purchases made by Member.
    </p>
    <p>
      <strong>Confidentiality</strong>: Member agrees to keep strictly confidential, hold in trust, and not disclose any
      Confidential Information (defined below) received from HealthTrust and its Affiliate GPOs. “Confidential
      Information” means any trade secrets or proprietary information including but not limited to programs, services,
      systems, pricing, agreements or information technology shared with Member by HealthTrust and its Affiliate GPOs.
    </p>
    <p>
      <strong>AUTHORITY; REPRESENTATIONS &amp; WARRANTIES</strong>: MEMBER REPRESENTS AND WARRANTS THAT IT IS AUTHORIZED
      TO ENTER INTO THIS AGREEMENT WITH HEALTHTRUST AND THAT EXECUTION OF THIS AGREEMENT WILL NOT VIOLATE ANY AGREEMENTS
      BETWEEN MEMBER AND ANY THIRD PARTY, INCLUDING BUT NOT LIMITED TO OTHER GROUP PURCHASING ORGANIZATIONS. HEALTHTRUST
      AND ITS AFFILIATE GPOS MAKE NO EXPRESS OR IMPLIED REPRESENTATION OR WARRANTIES UNDER THIS AGREEMENT, INCLUDING BUT
      NOT LIMITED TO ANY VENDOR AGREEMENT, AS TO THE PRODUCTS OR SERVICES OFFERED THEREUNDER, OR THEIR SUITABILITY FOR
      ANY PURPOSE. ANY ANALYSIS, PRICING, OR PRODUCT INFORMATION IS PROVIDED AS IS WITH NO GUARANTEE OF ACCURACY OR
      COMPLETENESS.
    </p>
    <p>
      <strong>LIMITATION OF LIABILITY</strong>: MEMBER ACKNOWLEDGES THAT MEMBER IS SOLELY RESPONSIBLE FOR DECIDING TO
      ENTER THIS AGREEMENT AND DETERMINING WHICH IF ANY PRODUCTS OR SERVICES TO PURCHASE THROUGH THE VENDOR AGREEMENTS.
      MEMBER ACKNOWLEDGES AND AGREES THAT HEALTHTRUST, ITS AFFILIATE GPOS AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
      SHAREHOLDERS, EMPLOYEES, AGENTS AND CONTRACTORS SHALL NOT HAVE ANY LIABILITY OF ANY NATURE OR KIND WHATSOEVER FOR
      ANY PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, ATTORNEY’S FEES, COURT COSTS OR ANY OTHER DAMAGES, LEGAL,
      EQUITABLE OR OTHERWISE, CAUSED EITHER DIRECTLY OR INDIRECTLY BY OR RELATED TO THE PRODUCTS OR SERVICES OFFERED OR
      SOLD UNDER THE VENDOR AGREEMENTS. MEMBER AGREES TO HOLD HEALTHTRUST AND ITS AFFILIATE GPOS HARMLESS FROM ALL
      LOSSES, DAMAGES AND COSTS, OF ANY NATURE OR KIND WHATSOEVER (INCLUDING, BUT NOT LIMITED TO ATTORNEY’S FEES AND
      COURT COSTS) INCURRED BY HEALTHTRUST OR ITS AFFILIATE GPOS FROM MEMBER’S BREACH OF ANY OF THE TERMS, CONDITIONS,
      AND REPRESENTATIONS &amp; WARRANTIES OF THIS AGREEMENT OR IN ANY OTHER WAY RELATING TO THIS AGREEMENT.
    </p>
    <p>
      <strong>Compliance with Law</strong>: Each party agrees that it shall at all times during the term of this
      Agreement comply with all applicable federal, state, and local laws and regulations in connection with its
      performance under this Agreement.
    </p>
    <p>
      <strong>Term &amp; Termination</strong>: The initial term of this Agreement will be for one year commencing on the
      date it is signed by HealthTrust. This Agreement will renew annually for an additional one-year terms upon
      expiration of the current term, unless either party gives to the other party written notice of its intent to
      terminate the Agreement not less than 60 days prior to the end of the current term. EITHER PARTY MAY TERMINATE
      THIS AGREEMENT FOR ANY REASON BY PROVIDING THE OTHER PARTY 60 DAYS’ PRIOR WRITTEN NOTICE.
    </p>
    <p><strong>This Agreement constitutes the entire understanding and agreement between the parties. In witness
        whereof, the parties hereto have executed this Agreement by persons duly authorized.</strong></p>

    <p>Member Organization: <strong>{{loc.facility_name}}</strong>
      <span *ngIf="loc.dba"> &nbsp;(DBA {{loc.dba}})</span>
      <br>
      Member POC: <strong>{{user.fname}} {{user.lname}}</strong> <br>
      Member Title: <strong>{{user.job_title}}</strong> <br>
      Acceptance Date: <strong>{{acceptanceDate}}</strong> <br>
      <span *ngIf="ip">IP Address: <strong>{{ ip }}</strong></span>
    </p>

    <p><strong>For AdvantageTrust:</strong></p>
    <img src="assets/img/signature.png" alt="Signature" style="width: 100px; height: auto;">
    <p><strong>Zachary Sikes</strong></p>
    <p><strong>Senior Vice President, AdvantageTrust</strong></p>

  </div>
  <div *ngSwitchCase="config.mgmaThemeID" class="tos-box">
    <h3 class="tos-header">Our Terms of Service for the Service</h3>
    <div>
      <p>MGMA BestPrice is provided as a free member benefit through membership in the MGMA. MGMA BestPrice accesses
        committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, MGMA
        BestPrice leverages HealthTrust, LLC and Purchase Clinic technology in the administration of the program.</p>
    </div>
    <p>Last Updated November 1, 2020</p>
    <p>***IMPORTANT, PLEASE READ THESE ONLINE TERMS OF SERVICE CAREFULLY. HEALTHTRUST, LLC IS THEADMINISTRATOR OF THIS
      PROGRAM.</p>
    <p>HealthTrust, LLC, a Tennessee limited liability company doing business as HealthTrust.com
      (“<strong>HealthTrust</strong>,” “<strong>we</strong>,” “<strong>our</strong>” or “<strong>us</strong>”), permits
      eligible companies that have completed their online registration to access and use the Platform available through
      websites under the control of HealthTrust (the “<strong>Websites</strong>”) to complete certain GPO-related
      transactions (the “<strong>Service</strong>”), conditioned on acceptance of these Terms of Service (“<strong>Terms
        of Service</strong>”).
    </p>
    <p>
      <strong>Note</strong>: These Terms of Service include a Disclaimer of warranties by HealthTrust (Section 8),
      Limitations on Liability and Remedies (Sections 9-10), Indeminication (Section 11), a dispute resolution provision
      (Section 13) that affect your rights with respect to the Service. Please review those Sections (and all other
      terms) carefully.
    </p>
    <strong>1. Acceptance of Terms of Service</strong>
    <p>These Terms of Service constitute a legal agreement between HealthTrust, the eligible corporation, limited
      liability company or other entity that elects to participate in the Service, whether as an entity that operates a
      medical practice, medical service, health and human service or other eligible service that has entered into our
      Participation Agreement (as defined below) and remains a member of our group purchasing organization (GPO) or an
      affiliated GPO (a “<strong>Member</strong>”) and each employee or representative of each Member who is issued a
      user name and password (collectively, “<strong>Authorized User</strong>,” “<strong>you</strong>” or
      “<strong>your</strong>”). Member is solely responsible for the acts and omissions of its Authorized Users and of
      any individual using the user name and password of its Authorized Users. As used herein, the terms “you,” “your”
      and words of similar import mean both Member in its legal capacity and the Authorized User in his or her
      individual capacity.
    </p>

    <p>
      <strong>If you are eligible to be a Member or Authorized User and desire to obtain a license to access and use the
        Service, you will be required to demonstrate your agreement to these Terms of Service by reviewing these Terms
        of Service and by indicating your acceptance as part of the registration process. By indicating your acceptance,
        you acknowledge that you have read, understand and agree to be bound by these Terms of Service. If you do not
        agree to be bound, you should not sign the “Signature” box, and you will not be provided access to the
        Service.</strong>
    </p>

    <p>These Terms of Service include a disclaimer of warranties, exclusive remedies and a disclaimer of liability, as
      well as indemnification by you, in Sections 8, 9, 10 and 11. Please review those sections (and all other terms)
      carefully. In addition, the Terms of Use for the Website contain additional restrictions on your use of the
      Website; such terms are hereby incorporated by reference. Please review those sections (and all other terms)
      carefully.</p>

    <p>Please also review our <strong>Privacy Policy http://purchaseclinic.com/privacy-policy/</strong>, which contains
      important information regarding the collection, use, disclosure and protection of your information, and our
      <strong>Terms of Use http://purchaseclinic.com/terms-of-use/</strong>, which sets forth the terms governing use of
      the Websites. Your agreement to these Terms of Service includes your agreement to our Privacy Policy and the Terms
      of Use. If there is a conflict between these Terms of Service, the Terms of Use and the Privacy Policy, the
      Privacy Policy shall have precedence with respect to the subject matter covered by it, the Terms of Use shall have
      precedence over general use of the Websites (as a visitor to the Websites) and otherwise these Terms of Service
      shall have precedence. If there is a conflict between these Terms of Service and the Participation Agreement, the
      Participation Agreement will control.
    </p>

    <p>
      <strong>NOTE THAT</strong>, HealthTrust may make changes to the Service or these Terms of Service at any time. We
      encourage you to review our Websites and these Terms of Service regularly for any such changes. Your continued
      access to or use of the Service shall be deemed your acceptance of these changes and the reasonableness of these
      standards for notice.
    </p>

    <p><strong>2. Eligibility to Use the Service; Registration.</strong></p>

    <p>The Service is limited to corporations, limited liability companies and other legal entities organized under the
      laws of a State in the USA, the employees and authorized representatives designated as authorized users by such
      legal entities and individuals resident in the United States that are eligible to and have entered into a
      Participation Agreement that has not been terminated. If You currently are a committed member of a third party
      group purchasing organization and/or obligated by contract to a third party to purchase exclusively from that
      organization, you are not eligible to become an Authorized User.В&nbsp;</p>

    <p>If you are eligible to become an Authorized User, you or your authorized representatives must set up an
      Authorized User account by completing the registration process prior to first using the Service, and each of your
      Authorized Users must also agree to these Terms of Service. You represent and warrant that the person establishing
      the account for each Authorized User is authorized to bind Authorized User to these Terms of Service. As part of
      the registration process, you must provide HealthTrust with complete and accurate information for each Authorized
      User, as prompted by the applicable registration form, including a valid email address. Each individual Authorized
      User will also choose a password and a user name. You agree that all information supplied by you in establishing
      your account(s) is accurate and complete, that you will maintain and promptly update the information, and that you
      consent to the storage of your information and content in the Service. If HealthTrust has reasonable grounds to
      suspect that your information is inaccurate or incomplete, HealthTrust may suspend or terminate your account(s),
      disable your password and refuse any and all current and future use of the Service, without prior notice.</p>

    <p>When you have completed the registration process, you will be authorized to use the Service until termination.
      Each Authorized User is entirely responsible for maintaining the confidentiality of user names and passwords. You
      agree to immediately notify HealthTrust of any known or suspected unauthorized use of your passwords, user names
      or accounts or any other breach of security. To the maximum extent permitted by applicable law, HealthTrust will
      not be liable for any loss that you may incur as a result of someone else using your passwords, user names or
      accounts, either with or without your knowledge, or for any inaccuracies or omissions in your data. However, you
      could be held liable for losses incurred by HealthTrust or a third party due to someone else using your account,
      user name or password.</p>

    <p><strong>3. Permitted Use of the Service</strong></p>

    <p>If you eligible to become an Authorized User, have agreed to these Terms of Service and your rights have not been
      terminated, HealthTrust will provide you access to the Service. You agree to access and use the Service, to the
      extent permitted by the functionality of the Websites, solely for the purposes of (a) accessing the HealthTrust
      platform (the “<strong>Platform</strong>”) in order to complete the GPO-related transactions permitted by the
      Websites, (b) accessing HealthTrust’s tools, interfaces, templates, designs, guidelines and other content
      incorporated into the Platform (“<strong>HealthTrust Content</strong>”) and (c) tracking your account. You
      understand that your access rights are personal, nonexclusive and nontransferable, that your rights may be
      terminated by HealthTrust if you do not abide by these Terms of Service and that you may have liability to
      HealthTrust and third parties if you misuse the Service.
    </p>

    <p><strong>4. Entering into Agreements; Transactions</strong></p>

    <p><strong>Participation Agreements</strong></p>

    <p>Authorized Users that are eligible to do so must enter into GPO participation agreements (“<strong>Participation
        Agreements</strong>”) by completing the registration process made available through the Websites and signing the
      “Signature” box or giving any other required form of assent, at which time the Authorized User will be a “Member”
      and subject to the terms and conditions of the applicable Participation Agreement(s). The registration process may
      permit eligible entities and individuals to become Authorized Users and Members through a single transaction, in
      which case by signing the “Signature” box (or giving any other required form of assent), you agree both to these
      Terms of Service and to the terms of the applicable Participation Agreement.
    </p>

    <p>If you are Member and wish to do so, you may access contract pricing for goods and services from third-party
      vendors that are made available to you through your Participation Agreement by clicking on a vendor’s link
      provided through the Websites. If you click a vendor’s link provided through the Websites to make such a purchase
      from a vendor, you will leave the Websites, and any purchase you make will be subject to the terms and conditions
      of the vendor offering such goods and services, including the terms of use, terms of service, privacy policy and
      other such terms of the vendor’s websites. Such terms and conditions may be different from the terms and
      conditions of these Websites.</p>

    <p>
      <strong>Members acknowledge and agree that transactions for the purchase of goods and services from vendors are
        agreements solely between the Member and the vendor of those goods and services and, to the greatest extent
        permitted by applicable law, Members disclaim all liability against HealthTrust and its affiliates for any loss
        or claim arising out of any such transaction. HealthTrust and its affiliates make no representation or warranty
        about vendors whose website links are made available through the Websites or about the goods and services
        offered by such vendors</strong>.
    </p>

    <p><strong>Compliance</strong></p>

    <p>Authorized User is solely responsible for complying with all laws applicable to its use of the Service.
      Authorized User agrees that it shall not make any statements in connection with its use of the Service that is
      false or misleading. Authorized User is solely confirming its eligibility to become an Authoritized User and
      access the Service, and is solely responsible for complying with all applicable terms and conditions imposed by
      third-party social networking services used in connection with the Service.</p>

    <p><strong>Reporting Fraudulent Transactions</strong></p>

    <p>If you believe someone has fraudulently taken actions under your account, you must notify HealthTrust immediately
      at <u>support@purchaseclinic.com</u></p>

    <p><strong>Authorized User Content</strong></p>

    <p>If permitted by the functionality of the Websites, you may upload content, logos and designs relating to your
      business and incorporate such content (“<strong>Authorized User Content</strong>”) into the Platform. You hereby
      grant us and others, including third parties, a right and license to use, copy, host, display modify and create
      derivative works of all Authorized User Content you upload into the Service for the purposes of making the Service
      available, for the performance of our obligations under these Terms of Service and for HealthTrust’s marketing
      purposes (as described in Section 14 below). You represent and warrant (a) that you have all necessary rights and
      licenses to grant the rights granted herein and for us and our vendors to use and display all Authorized User
      Content as contemplated herein, (b) that the Authorized User Content does not infringe upon, violate or
      misappropriate the intellectual property rights of any third parties, (c) that the Authorized User Content is not
      libelous, defamatory, offensive, insulting, derogatory or likely to bring HealthTrust into disrepute, and (d) that
      the Authorized User Content does not include any material or information that is subject to a third party’s right
      of privacy or that, if disclosed to third parties, would violate or breach any applicable law, rule or regulation.
      Without limiting the foregoing, you represent and warrant that the Authorized User Content you upload into the
      Service will not include any Social Security numbers, personal health information or non-public financial
      information. As between you and HealthTrust, you retain ownership of Authorized User Content, subject to the
      limited rights granted herein.
    </p>

    <p><strong>5. Other Restrictions</strong></p>

    <p>HealthTrust reserves all rights in the Service not granted in these Terms of Service. Without limiting the
      foregoing, unless expressly permitted hereunder, by using the Service you agree not to:</p>

    <p></p>
    <ol>
      <li>translate, modify or create derivative works of the Service;</li>
      <li>upload, download, recreate, display, perform, post, reproduce or copy the Service, except to the extent
        permitted herein;</li>
      <li>disclose, publish, distribute, sell, assign, lease, sublicense or transfer the Service;</li>
      <li>attempt to derive the source code, source files or structure of the software contained in the Service by
        reverse engineering, disassembly, decompilation or any other means;</li>
      <li>use the Service to create a service bureau or for any other use involving processing of data for other persons
        or entities;</li>
      <li>use the Service except in accordance with all applicable law;</li>
      <li>introduce into the Service any “malware,” such as, but not limited to, viruses, worms and Trojan Horses;</li>
      <li>use deep-links, page scrapes, web crawlers, web robots, spiders, wanderers, web scutters, ants, automatic
        indexers, bots, worms, or other such devices, or programs, algorithms or methodologies which do the same things
        in connection with the Service, or use other automated processes to access or use the Service;</li>
      <li>attempt to access the accounts of any user of the Service other than your own account;</li>
      <li>post, transmit or link from any unlawful, infringing, misleading, deceptive, threatening, libelous,
        defamatory, plagiarized, fraudulent, harassing, obscene, discriminatory, inflammatory, pornographic or profane
        material, spam or any material that could constitute or encourage conduct that would be considered a criminal
        offense, give rise to civil liability, or would otherwise violate applicable law;</li>
      <li>use the Service in any manner that could damage, disable, undermine, overburden or impair the Service or the
        servers on which it runs or interfere with any other party’s use of the Service;</li>
      <li>obtain or attempt to obtain any content through any means not intentionally made available or provided for
        through the Service; or</li>
      <li>use any of the logos, trademarks, service marks or other indicators of origin appearing on the Service.</li>
    </ol>

    <strong>6. Copyrights and Other Proprietary Rights</strong>
    <p></p>

    <p>Authorized User acknowledges and agrees that HealthTrust and its licensors retain all ownership rights in the
      Service including the Websites, all HealthTrust software technology that is incorporated into or made available
      through the Websites, any downloadable software or related technical information for Platform integration
      (including, if applicable, application program interfaces) that is made available through the Websites and all
      modifications and improvements thereto (“<strong>HealthTrust Technology</strong>”) and that you do not receive any
      ownership rights or license rights, except as set forth herein, by accessing or using the Service or consenting to
      these Terms of Service. The entire content of the Service, the Websites and the HealthTrust Technology,<strong>
      </strong>including but not limited to text, design, software, photography, video, graphics, music, sound,
      information and the selection, coordination, arrangement, and enhancement thereof, is protected under the
      copyright laws of the United States, international treaties and other intellectual property laws (including
      without limitation the copyright in the selection, coordination, arrangement and enhancement of all content).
    </p>

    <p><strong>7. Confidential Information</strong></p>

    <p><strong>Definition</strong></p>

    <p>“<strong>Confidential Information</strong>” means information relating to the products, services or business
      affairs of HealthTrust which is of a proprietary or confidential nature, whether communicated orally or in
      writing, including, but not limited to, pricing information, print product printing volumes, the HealthTrust
      Technology, and HealthTrust’s concepts, techniques, processes, designs, documentation, flow-charts, diagrams,
      instructions, computer programs, technical know-how, information and trade secrets disclosed by HealthTrust to
      Authorized Users under this Agreement (each, a “<strong>Recipient</strong>”). Confidential Information shall also
      include any information of a confidential nature concerning HealthTrust’s financial affairs or business and any
      information HealthTrust has received from others which HealthTrust is obligated to treat as confidential or
      proprietary.
    </p>

    <p><strong>No Unauthorized Disclosure/Use</strong></p>

    <p>The Recipient acknowledges that irreparable injury and damage will result from disclosure of the Confidential
      Information to unauthorized third parties or from utilization of the Confidential Information for purposes other
      than those connected with the business relationship of the parties hereto. The Recipient shall not, without the
      prior written consent of HealthTrust, disclose any Confidential Information to any third party other than
      Authorized User’s employees and personnel who have a need to know and who are bound by obligations of
      confidentiality consistent with this Section 7. The Recipient shall not use the Confidential Information except to
      perform its obligations and exercise its rights under these Terms of Service. The Recipient shall not be in breach
      of this section if disclosure of Confidential Information is made pursuant to subpoena or other compulsory
      judicial process, the Recipient promptly notifies HealthTrust of such subpoena or other compulsory process, and
      provides reasonable assistance at HealthTrust’s request and expense so that HealthTrust may seek a protective
      order or take such other action it deems necessary to protect its interests.</p>

    <p><strong>Protection of Confidential Information</strong></p>

    <p>The Recipient agrees to take all necessary security precautions to protect the Confidential Information from
      unauthorized disclosure, including, without limitation, restricting access thereto and protecting documents
      containing Confidential Information from theft and from the unauthorized duplication or discovery of their
      contents.</p>

    <p><strong>Return of Materials</strong></p>

    <p>At any time upon HealthTrust’s request, the Recipient will promptly return to HealthTrust all written material,
      in whatever form or media, containing or reflecting any Confidential Information of HealthTrust and will not
      retain any copies, extracts, or other reproductions, in whole or in part, of such written material. All documents,
      memoranda, notes, and other writings whatsoever, in whatever form or media, (including all copies, extracts, or
      other reproductions) prepared by or on behalf of the Recipient that are based on the information contained in the
      Confidential Information of HealthTrust will be destroyed, and such destruction will be certified in writing to
      HealthTrust by the Recipient. The return of such material will not relieve the Recipient’s obligation of
      confidentiality or other obligations hereunder.</p>

    <p><strong>8. Disclaimer</strong></p>

    <p>
      <strong>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE SERVICE IS
        PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTIES OF ANY KIND, AND PURCHASECLINIC AND ITS LICENSORS
        DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND WARRANTIES ARISING FROM COURSE
        OF DEALING, COURSE OF PERFORMANCE AND USAGE OF TRADE. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.
        FURTHERMORE, PURCHASECLINIC AND ITS LICENSORS AND VENDORS DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED
        OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
        OR CODE OR THAT THE USE OF THE SERVICE WILL COMPLY WITH THE RULES OF ANY THIRD PARTY SOCIAL NETWORKING WEBSITE.
        ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION
        AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
        FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</strong>
    </p>

    <p><strong>9. Exclusive Remedy</strong></p>

    <p>If you are dissatisfied with the Service (including without limitation these Terms of Service), you acknowledge
      and agree that your <strong>sole and exclusive remedy</strong> is to discontinue using the Service.</p>

    <p><strong>10. Limitations on Liability</strong></p>

    <p>
      <strong>NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS OF SERVICE OR OTHERWISE, TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, PURCHASECLINIC AND ITS VENDORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY LOST OR
        CORRUPTED DATA OR CONTENT, ANY ERRORS CAUSED BY THE TOOLS AVAILABLE THROUGH THE WEBSITE, ANY TERMINATION,
        SUSPENSION OR OTHER LOSS OF YOUR OR A THIRD PARTY’S SOCIAL NETWORKING ACCOUNT OR ANY SPECIAL, INDIRECT,
        INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF USE OF THE SERVICE OR ANY SUBJECT
        MATTER OF THESE TERMS OF SERVICE, EVEN IF PURCHASECLINIC HAS BEEN ADVISED OF OR COULD HAVE FORESEEN THE
        POSSIBILITY OF SUCH DAMAGES UNDER ANY THEORY OF LIABILITY. IN NO EVENT WILL PURCHASECLINIC HAVE ANY LIABILITY TO
        YOU OR ANY THIRD PARTY ARISING FROM YOUR USE OF THE SERVICE WHICH IN THE AGGREGATE IS MORE THAN ONE HUNDRED
        DOLLARS ($100.00).</strong>
    </p>

    <p><strong>11. Indemnification</strong></p>

    <p>HealthTrust and its licensors, vendors, insurers, agents and commercial partners and our respective officers,
      directors, stockholders, employees and agents are, collectively, the “<strong>HealthTrust Parties</strong>.” You
      agree to indemnify and hold the HealthTrust Parties harmless from and against any and all claims, demands,
      liabilities, losses, damages, costs and expenses, including reasonable attorney’s fees, that arise from any
      content or data you post, transmit or link from on the Websites or the Service, your misuse of the Websites or the
      Service, including, but not limited to, your violation of these Terms of Use or, if applicable to you, the Terms
      of Service, your violation of applicable law or your violation of any rights of any third party.
    </p>

    <p><strong>12. Termination</strong></p>

    <p>Subject to applicable law, HealthTrust reserves the right to terminate, suspend or deny, in its sole discretion,
      your access to all or any portion of the Service, without prior notice, if (a) Authorized User breaches or
      threatens to breach these Terms of Service, (b) Authorized User breaches or threatens to breach, as applicable,
      the applicable Participation Agreement, (c) an officer or executive of Authorized User inform us in writing that
      you are no longer authorized to use the Service, (d) if required to do so by operation of law, or (e) no activity
      occurs in your account or in the account of Authorized User for a period of ninety (90) days. In addition, if
      Authorized User is not a party to a then-current Participation Agreement, either party may terminate this
      agreement for convenience upon thirty (30) days’ prior written notice, provided that HealthTrust may give such
      notice by email using the email address you provided to us in registration. If either party terminates for
      convenience in accordance with the prior sentence prior to the termination of a Participation Agreement,
      termination shall be effective thirty (30) days after termination of the last effective Participation Agreement.
      Upon termination of this Agreement, you acknowledge and agree that HealthTrust may immediately deactivate or
      delete your account and all related data and files in your account, bar any further access to such data, files and
      the Service, and disable your password on termination of these Terms of Service. The following terms shall survive
      any termination of these Terms of Service: Sections 5, 6, 7, 9, 10, 11, 12, 13, 14 and 15.</p>

    <p><strong>13. Governing Law; Jurisdiction; Venue</strong></p>

    <p>HealthTrust controls the Service from its offices within the United States, and your data is stored in servers
      managed by HealthTrust in the United States. HealthTrust makes no representation that the Service is appropriate,
      may be downloaded, or is available for use outside the United States. Access to the Service where the content or
      access or use of the Service is illegal is prohibited. Those who choose to access and use the Service from outside
      the United States do so on their own initiative, at their own risk, and are responsible for compliance with
      applicable local laws and U.S. export and other applicable laws.</p>

    <p>The laws of the State of Tennessee will govern these Terms of Service, without reference to its conflicts of law
      principles, except that the federal law of the United States shall apply to questions regarding the validity,
      infringement or enforceability of U.S. federal patent, copyright and trademark rights relating in any way to these
      Terms of Service, or the Service. English is the only language applicable to these Terms of Service. You agree to
      submit to the exclusive jurisdiction of, and waive any venue objections and defenses of lack of personal
      jurisdiction against, the State and Federal courts located in Tennessee, except that you acknowledge that any
      breach of Sections 3, 5 and 7 cannot reasonably or adequately be compensated by damages in an action at law and
      that a breach or threatened breach of such provisions shall cause HealthTrust irreparable injury and damage, and
      HealthTrust shall be entitled, in addition to any other remedies it may have, to preliminary and permanent
      injunctive and other equitable relief to prevent or curtail any actual or threatened breach in any court of
      competent jurisdiction. Process may be served on you in the manner authorized by applicable law or court rule.</p>

    <p>ALL PARTIES TO ANY LITIGATION MUST BE INDIVIDUALLY NAMED. THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO
      BE LITIGATED ON A CLASS ACTION OR CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A PURPORTED
      REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER AUTHORIZED
      USERS, OR OTHER PERSONS.</p>

    <p><strong>14. Marketing by HealthTrust; Use of Authorized User Logos</strong></p>

    <p>Authorized User agrees that HealthTrust may list Authorized User’s business identity in marketing materials and
      at marketing events (like trade shows) and to display Authorized User’s logos (to the extent Authorized User is a
      legal entity and not an individual) on such lists, in such marketing materials and at such marketing events.
      HealthTrust agrees to follow Authorized User’s reasonable instructions regarding the display of any Authorized
      User trademarks. With Authorized User’s written permission, HealthTrust may prepare and publish case studies
      describing Authorized User’s experience with the Platform for HealthTrust’s marketing purposes. Unless Authorized
      User agrees in writing, such case studies will not identify Authorized User by name.</p>

    <p><strong>15. Miscellaneous Terms</strong></p>

    <p>If any provision of these Terms of Service is held to be unenforceable, that provision will be removed and the
      remaining provisions will remain in full force. The failure of either you or HealthTrust to require performance by
      the other party of any provision of these Terms of Service will not affect the right to require performance at any
      time in the future; nor will the waiver by either party of a breach of any provision be taken or held to be a
      waiver of the provision itself. These Terms of Service (including without limitation our Privacy Policy and Terms
      of Service) are the entire and exclusive agreement between you and HealthTrust with respect to your access and use
      of the Service.<strong> </strong>You may not assign this agreement or any portion thereof, in whole or in part,
      including by operation of law, without HealthTrust’s prior written consent. HealthTrust may freely assign this
      agreement and delegate its obligations hereunder. Except as otherwise provided herein, any notice permitted or
      required to be given under these Terms of Service shall be deemed sufficient if given by registered or certified
      mail, postage prepaid, return receipt requested, by private courier service or by facsimile or email addressed to
      your address as provided upon your registration and/or to HealthTrust, attn.: Support at 10221 River Road #60053,
      Potomac, MD, 20854, or to such other addresses as the parties may designate by like notice from time to time. A
      notice so given shall be effective upon (a) receipt by the party to which the notice is given, or (b) on the fifth
      day following domestic mailing or the tenth day following international mailing, whichever occurs first. Any
      notice permitted under this Agreement to be given to HealthTrust via email will be effective only upon actual
      receipt by HealthTrust of an email message from an email address registered in connection with your account. Any
      delays in performance by HealthTrust under this Agreement will not be considered a breach of this Agreement. There
      are no third party beneficiaries to this Agreement.
    </p>

    <h3 class="tos-header">Participation Agreement</h3>

    <p>This Group Purchasing Organization Participation Agreement is entered into by and between HealthTrust, LLC, a
      Tennessee limited liability company (“HealthTrust”), and “Member” indicated below. HealthTrust and Member agree as
      follows:</p>

    Date: <strong>{{acceptanceDate}}</strong><br>
    <span *ngIf="ip">Your IP Address is <strong>{{ip}}</strong></span>

    <h2 style="margin: 1em 0 0.5em; padding: 0; font-size: 150%">Member</h2>

    <p>Organization’s Legal Name: <strong>{{loc.facility_name}}</strong><br />
    </p>

    <p>Street Address:<br>
      <span style="font-weight: bold">{{loc.address}} {{loc.address2}}<br> {{loc.city}}, {{loc.state}}
        {{loc.zip}}</span>
    </p>
    <p>Phone Number: <strong>{{user.phone}}</strong></p>

    <p><strong>Contact person</strong> to receive information about HealthTrust programs and savings opportunities:</p>
    <p>Name: <strong>{{user.fname}} {{user.lname}}</strong><br>
      Title: <strong>{{user.job_title}}</strong><br>
      E-Mail Address: <strong>{{user.email}}</strong><br>
    </p>

    <p><strong>Additional Locations:</strong> This Agreement shall apply collectively to all Member&rsquo;s current and
      future Locations. Current Location(s) are identified above and may be listed in Schedule 1. A list of all
      Member&rsquo;s Locations to which this Agreement applies is viewable in Member&rsquo;s Purchase Clinic account and
      is available upon request. Member warrants that it owns and/or manages Locations and has legal authority to enter
      into this Agreement on their behalf. Member shall provide prompt written notice of all additions/changes to
      Locations. </p>
    <p><strong>Exclusive Group Purchasing Agency:</strong> Member authorizes HealthTrust as its exclusive group
      purchasing and contracting services agent to negotiate and enter into agreements with vendors in order to make
      agreements available to Member. Member authorizes HealthTrust as its sole agent to negotiate and enter into
      affiliation agreements with other group purchasing organizations (&ldquo;Affiliate GPOs&rdquo;) and to enroll
      Member in Affiliate GPOs in order to make their agreements with vendors available to Member. </p>
    <p><strong>Vendor Agreements:</strong> The agreements made available to Member whether entered into by HealthTrust
      or any Affiliate GPO shall be cumulatively referred to as &ldquo;Vendor Agreements&rdquo;. Member agrees to comply
      with the terms and conditions of any Vendor Agreement through which it chooses to make any purchase. </p>
    <p><strong>NO OBLIGATION TO PURCHASE:</strong> MEMBER IS NOT OBLIGATED TO MAKE ANY PURCHASE UNDER THIS AGREEMENT.
    </p>
    <p><strong>Member Updates; Authorization to Use Member Signature and Information for Enrollment:</strong> Member is
      required to update its enrollment with HealthTrust if there is a change to its contact person or relevant changes
      in the scope of authority of the person who executed this Agreement on Member&rsquo;s behalf. Member understands
      and acknowledges that HealthTrust is authorized to rely upon and use the Member information and signature of the
      authorized person provided during the enrollment and any subsequent enrollment processes unless Member notifies
      HealthTrust of a change. Member authorizes HealthTrust to capture and use that information and electronic
      signature of the authorized person provided during enrollment processes to populate documentation necessary to
      enroll Member in Affiliate and administered GPOs, including declaration, commitment and other required vendor and
      distributor forms, and to update or change Member&rsquo;s buying group affiliation in accord with this Agreement.
      Authorized uses of such information and signature by HealthTrust include execution of letters of commitment and
      ensuring Member is in an appropriate tier based on its ability to commit, but HealthTrust will not use such
      information to obligate Member to may any purchases. HealthTrust will maintain a copy of any documentation it has
      submitted using Member&rsquo;s information and signature of its authorized person. Member may opt out of this
      authorization or withdraw it at any time by sending a request in writing to: <a
        href="mailto:support@purchaseclinic.com">support@purchaseclinic.com</a>Own Use: Member represents and warrants
      that any purchase made through any Vendor Agreement under this Agreement will be for Member&rsquo;s &ldquo;Own
      Use&rdquo; and not for resale.</p>
    <p><strong>Rebates:</strong> Any rebates due to Member based on Member&rsquo;s purchases through any Vendor
      Agreement, if received by HealthTrust or its Affiliate GPOs, will be forwarded to Member at least quarterly.
      Member is solely responsible for reporting any such rebate as required by law. </p>
    <p><strong>Administrative Fees:</strong> HealthTrust discloses to Member that it and its Affiliate GPOs receive
      administrative fees from contracted vendors based on purchases made by Member. The administrative fee varies
      according to each Vendor Agreement and is generally (3%) or less of the purchase price of the goods or services
      purchased by Member; HealthTrust or its Affiliate GPO will report to Member any administrative fee that is greater
      than three percent (3%). HealthTrust and its Affiliate GPOs will report to Member annually, and to the Secretary
      of the U.S. Department of Health and Human Services upon request, the fees received under each Vendor Agreement
      for purchases made by Member. </p>
    <p><strong>Confidentiality:</strong> Member agrees to keep strictly confidential, hold in trust, and not disclose
      any Confidential Information (defined below) received from HealthTrust and its Affiliate GPOs. &ldquo;Confidential
      Information&rdquo; means any trade secrets or proprietary information including but not limited to programs,
      services, systems, pricing, agreements or information technology shared with Member by HealthTrust and its
      Affiliate GPOs. </p>
    <p><strong>AUTHORITY; REPRESENTATIONS &amp; WARRANTIES:</strong> MEMBER REPRESENTS AND WARRANTS THAT IT IS
      AUTHORIZED TO ENTER INTO THIS AGREEMENT WITH HEALTHTRUST AND THAT EXECUTION OF THIS AGREEMENT WILL NOT VIOLATE ANY
      AGREEMENTS BETWEEN MEMBER AND ANY THIRD PARTY, INCLUDING BUT NOT LIMITED TO OTHER GROUP PURCHASING ORGANIZATIONS.
      HEALTHTRUST AND ITS AFFILIATE GPOS MAKE NO EXPRESS OR IMPLIED REPRESENTATION OR WARRANTIES UNDER THIS AGREEMENT,
      INCLUDING BUT NOT LIMITED TO ANY VENDOR AGREEMENT, AS TO THE PRODUCTS OR SERVICES OFFERED THEREUNDER, OR THEIR
      SUITABILITY FOR ANY PURPOSE. ANY ANALYSIS, PRICING, OR PRODUCT INFORMATION IS PROVIDED AS IS WITH NO GUARANTEE OF
      ACCURACY OR COMPLETENESS. </p>
    <p><strong>LIMITATION OF LIABILITY:</strong> MEMBER ACKNOWLEDGES THAT MEMBER IS SOLELY RESPONSIBLE FOR DECIDING TO
      ENTER THIS AGREEMENT AND DETERMINING WHICH IF ANY PRODUCTS OR SERVICES TO PURCHASE THROUGH THE VENDOR AGREEMENTS.
      MEMBER ACKNOWLEDGES AND AGREES THAT HEALTHTRUST, ITS AFFILIATE GPOS AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
      SHAREHOLDERS, EMPLOYEES, AGENTS AND CONTRACTORS SHALL NOT HAVE ANY LIABILITY OF ANY NATURE OR KIND WHATSOEVER FOR
      ANY PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, ATTORNEY&rsquo;S FEES, COURT COSTS OR ANY OTHER DAMAGES,
      LEGAL, EQUITABLE OR OTHERWISE, CAUSED EITHER DIRECTLY OR INDIRECTLY BY OR RELATED TO THE PRODUCTS OR SERVICES
      OFFERED OR SOLD UNDER THE VENDOR AGREEMENTS. MEMBER AGREES TO HOLD HEALTHTRUST AND ITS AFFILIATE GPOS HARMLESS
      FROM ALL LOSSES, DAMAGES AND COSTS, OF ANY NATURE OR KIND WHATSOEVER (INCLUDING, BUT NOT LIMITED TO
      ATTORNEY&rsquo;S FEES AND COURT COSTS) INCURRED BY HEALTHTRUST OR ITS AFFILIATE GPOS FROM MEMBER&rsquo;S BREACH OF
      ANY OF THE TERMS, CONDITIONS, AND REPRESENTATIONS &amp; WARRANTIES OF THIS AGREEMENT OR IN ANY OTHER WAY RELATING
      TO THIS AGREEMENT. </p>
    <p><strong>Compliance with Law:</strong> Each party agrees that it shall at all times during the term of this
      Agreement comply with all applicable federal, state, and local laws and regulations in connection with its
      performance under this Agreement. </p>
    <p><strong>Term &amp; Termination:</strong> The initial term of this Agreement will be for one year commencing on
      the date it is signed by HealthTrust. This Agreement will renew annually for an additional one-year terms upon
      expiration of the current term, unless either party gives to the other party written notice of its intent to
      terminate the Agreement not less than 60 days prior to the end of the current term. EITHER PARTY MAY TERMINATE
      THIS AGREEMENT FOR ANY REASON BY PROVIDING THE OTHER PARTY 60 DAYS&rsquo; PRIOR WRITTEN NOTICE. </p>
    <p>This Agreement constitutes the entire understanding and agreement between the parties. In witness whereof, the
      parties hereto have executed this Agreement by persons duly authorized.</p>
    <p><strong>Distributor Primary GPO (Group Purchasing Organization)</strong></p>
    <p><strong>Designation Form</strong></p>
    <p>Request Date:</p>
    <p>Customer Account Name:</p>
    <p>Distributor Name/Company:</p>
    <p>Distributor Representative:</p>
    <p>Distributor Bill to Account Number(s):</p>
    <p>Any Ship To Account Under the Bill To(s) will be included in the Designation.</p>
    <p> </p>
    <p>Any Exclusions/Exception to the Above Account(s) Designations:</p>
    <p>Please Enter Distributor Account Number(s) separated by commas</p>
    <p> </p>
    <p>Please Designate your Preferred / Primary GPO for the above Account(s)</p>
    <p>HealthTrust (HPG): </p>
    <p>HealthTrust GPOID(S):</p>
    <p>Please Check &ldquo;Yes&rdquo; to Designate the same GPO for RX?</p>
    <p>[X] Yes</p>
    <p>[ ] No</p>
    <p>[ ] No RX Required</p>
    <p>I confirm I am the am the authorized signatory for the facilities listed above and that each such facility
      designates the Primary GPO referenced above as its primary GPO affiliation of record as of the Effective Date
      listed above. This primary GPO designation supersedes all prior declarations and will remain in effect until any
      future written designation form is submitted.</p>
    <p>[x] I Authorize</p>
    <p>[ ] I Do Not Authorize</p>
    <p>[ ] I Need More Information</p>
    <p>Authorized Signatory Full Name:</p>
    <p>Authorized Signatory Full Email:</p>
    <p>Signature &amp; Date:</p>
    <hr />
    <p><strong>McKesson Primary GPO (Group Purchasing Organization)</strong></p>
    <p><strong>Designation Form</strong></p>
    <p>Request Date:</p>
    <p>Customer Account Name:</p>
    <p>McKesson Bill to Account Number(s):</p>
    <p>Any Ship To Account Under the Bill To(s) will be included in the Designation.</p>
    <p> </p>
    <p>Any Exclusions/Exception to the Above Account(s) Designations:</p>
    <p>Please Enter McKesson Account Number(s) separated by commas</p>
    <p> </p>
    <p>Please Designate your Preferred / Primary GPO for the above Account(s)</p>
    <p>HealthTrust (HPG): </p>
    <p>HealthTrust GPOID(S):</p>
    <p>Please Check &ldquo;Yes&rdquo; to Designate the same GPO for RX?</p>
    <p>[X] Yes</p>
    <p>[ ] No</p>
    <p>[ ] No RX Required</p>
    <p>I confirm I am the am the authorized signatory for the facilities listed above and that each such facility
      designates the Primary GPO referenced above as its primary GPO affiliation of record with McKesson Medical
      Surgical as of the Effective Date listed above. This primary GPO designation supersedes all prior declarations and
      will remain in effect until any future written designation form is submitted by the customer to replace this
      designation.</p>
    <p>[x] I Authorize</p>
    <p>[ ] I Do Not Authorize</p>
    <p>[ ] I Need More Information</p>
    <p>Authorized Signatory Full Name:</p>
    <p>Authorized Signatory Full Email:</p>
    <p>Signature &amp; Date: </p>
    <hr />
    <p><strong>MANUFACTURER/SUPPLIER GPO NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust </p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that</p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust Agreement(s) for products and services covered under
      group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as a member of HealthTrust. Pursuant to this
      declaration, all sales under contract must be reported to HealthTrust. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>
    <p>Authorized Distributor(s): ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: ________________________________________</p>
    <p>Signature &amp; Date: _______________________________________</p>
    <hr />
    <p><strong>MANUFACTURER/SUPPLIER GPO NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust LLC</p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that </p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust LLC Agreement(s) for products and services covered under
      group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as a member of HealthTrust LLC. Pursuant to this
      declaration, all sales under contract must be reported to HealthTrust LLC. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust LLC GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>
    <p>Authorized Distributor(s): ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: ________________________________________</p>
    <p>Signature &amp; Date: ________________________________________</p>
    <hr />
    <p><strong>PHARMACY PROGRAM NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust</p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that </p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust Pharmacy Agreement(s) for products and services covered
      under group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as member of HealthTrust. Pursuant to this declaration,
      all sales under contract must be reported to HealthTrust. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>
    <p>Authorized Distributor(s): ________________________________________</p>
    <p>DEA or HIN #: ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: _______________________________________</p>
    <p>Signature &amp; Date: ________________________________________</p>
    <hr />
    <p><strong>PHARMACY PROGRAM NOTIFICATION FORM</strong> </p>
    <p>Manufacturers and Suppliers contracted with HealthTrust LLC</p>
    <p>To Whom It May Concern: </p>
    <p>This letter is to serves as notice that </p>
    <p>_______________________________________________________ </p>
    <p>and its site(s) are electing to utilize the HealthTrust LLC Pharmacy Agreement(s) for products and services
      covered under group purchasing contract(s) by your organization.</p>
    <p>As an authorized representative, I request consideration as member of HealthTrust LLC. Pursuant to this
      declaration, all sales under contract must be reported to HealthTrust LLC. </p>
    <p>This letter supersedes any prior declarations or GPO assignments and will remain in effect until written notice
      of a change. </p>
    <p>HealthTrust LLC GPOID: ________________________________________</p>
    <p>Primary Address: ________________________________________</p>
    <p>Authorized Distributor(s): ________________________________________</p>
    <p>DEA or HIN #: ________________________________________</p>
    <p>Authorized POC: ________________________________________</p>
    <p>Authorized POC Title: ________________________________________</p>
    <p>Signature &amp; Date: ________________________________________</p>

  </div>
</div>
