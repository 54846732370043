<div class="container review-layout">
  <div class="row">
    <div class="col-sm-10 offset-sm-1 mw-100">
      <div class="bordered-container">
        <h1 class="headline">Sign up</h1>
        <div class="bordered-content clearfix">
          <form class="form-validate body-form" (ngSubmit)="submit()" *ngIf="!loc?.pa_date">
            <div class="alert alert-danger alert-dismissible" *ngIf="isError">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                  aria-hidden="true">&times;</span></button>
              <span *ngIf="errMsg">{{errMsg}}</span>
              <span *ngIf="!errMsg">We are sorry, error happened on the server. Please contact us at <a target="_blank"
                  href="mailto:support@purchaseclinic.com">support@purchaseclinic.com</a>.</span>
            </div>
            <form-header>Please review the following information and then click "Sign Up" to activate your free
              membership.</form-header>

            <form-subtitle>Your contact information</form-subtitle>

            <div class="row">
              <div class="col-md-6">
                <form-field [(model)]="user.fname" label="First Name" [required]="true"></form-field>
              </div>
              <div class="col-md-6">
                <form-field [(model)]="user.lname" label="Last Name" [required]="true"></form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <fieldset class="form-group required">
                  <label class="col-form-label">Email</label>
                  <input type="email" [(ngModel)]="user.email" #elEmail required class="form-control"
                    placeholder="Email" name="email" autocomplete="new-password" />
                </fieldset>
              </div>
              <div class="col-md-6">
                <form-field [(model)]="user.phone" label="Phone" [required]="true"></form-field>
              </div>
            </div>

            <form-subtitle>Organization information</form-subtitle>

            <div class="row">
              <div class="col-md-6">
                <form-field [(model)]="loc.facility_name" label="Organization’s Legal Name"
                  [required]="true"></form-field>
              </div>
              <div class="col-md-6">
                <form-field [(model)]="loc.address" label="Organizational Address"
                  [required]="true"></form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <form-field [(model)]="loc.state" label="Organizational’s state"
                  [required]="true"></form-field>
              </div>
              <div class="col-md-6">
                <form-field [(model)]="loc.zip" label="Organizational’s Zip" [required]="true"></form-field>
              </div>
            </div>

            <section *ngIf="distributorsList.length && !loc.__rep_user_id && !loc.__distributor_id"
              class="form-group select-group">
              <form-subtitle>More information</form-subtitle>

              <div class="row">
                <div class="col-md-6">
                  <fieldset>
                    <label>Medical/Surgical Distributor</label>
                    <select [ngModel]="loc.__distributor_id" (change)="loc.__distributor_id = $event.target.value"
                      class="form-control" [ngModelOptions]="{standalone: true}">
                      <option value="0" disabled>Please select</option>
                      <option *ngFor="let dist of distributorsList" value="{{dist.id}}">{{dist.name}}</option>
                    </select>
                  </fieldset>
                </div>
              </div>
            </section>

            <div class="form-group mt-3">
              <div class="checkbox">
                <label>
                  <input type="checkbox" #tos (change)="custom.signature = tos.checked ? '--electronic signature--':''"
                    class="zoomed-checkbox" />
                  I agree to the Terms of Service, Privacy Policy and Participation Agreement provided below.
                </label>
              </div>
            </div>
            <div class="form-group  float-right clearfix">
              <div class="form-action">
                <button type="submit" class="btn btn-primary btn-block btn-action ico-right btn-continue"
                  [attr.data-hidden]="saving"><span>Continue</span><span class="icon-continue_arrow"></span></button>
                <spinner spinnerType="throbber-loader" [attr.data-hidden]="!saving"></spinner>
              </div>
            </div>
            <a href="" class="pc2-terms-link" data-toggle="modal" data-target="#termsModal">Read our Terms of Service,
              Privacy Policy and Participation Agreement</a>
            <div style="margin-top: 30px">
              <ng-hcaptcha #captchaElem (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
                (success)="handleSuccess($event)" (verify)="onVerify($event)" formControlName="captcha">
              </ng-hcaptcha>
            </div>
          </form>
          <div class="card" *ngIf="loc?.pa_date">
            <div class="card-header solo text-center text-primary">
              The enrollment for this facility was completed <span *ngIf="signedDate">on
                {{signedDate|utc2et|date:'longDate'}}</span>.
            </div>
            <div class="card-body">
              <div class="text-center">
                <a routerLink="/signup" class="btn btn-primary">New Enrollment</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Terms of Service, Privacy Policy and Participation Agreement</h4>
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span
            class="sr-only">Close</span></button>
      </div>
      <div class="modal-body high-modal">
        <tos-text [user]="user" [loc]="loc" [signature]="custom.signature"></tos-text>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
