<div [ngClass]="{'mgma-footer': themeID == 4}">
  <div class="container" [ngClass]="{'mgma-footer': themeID == 4}">
    <div class="ftr-top">
      <div class="footer-content">
        <div class="row">
          <div class="col-sm-5 col-md-3 add-links ov-h">
            <h4 class="headline">Additional links</h4>
            <ul class="list-unstyled">
              <li>
                <a [ngClass]="{'mgma-link': themeID == 4}" *ngIf="!session.user?.__theme?.faq_url"
                  routerLink="/faq">FAQ</a>
                <a [ngClass]="{'mgma-link': themeID == 4}" *ngIf="session.user?.__theme?.faq_url"
                  href="{{session.user?.__theme?.faq_url}}">FAQ</a>
              </li>
              <li><a [ngClass]="{'mgma-link': themeID == 4}" routerLink="/account">My Account</a></li>
              <li><a [ngClass]="{'mgma-link': themeID == 4}" routerLink="/contact">Contact Us</a></li>
              <li><a [ngClass]="{'mgma-link': themeID == 4}" routerLink="/privacy-policy">Privacy Policy</a></li>
              <li><a [ngClass]="{'mgma-link': themeID == 4}" routerLink="/terms-of-service">Terms of Service</a></li>
              <li><a [ngClass]="{'mgma-link': themeID == 4}" routerLink="/terms-of-use">Terms of Use</a></li>
            </ul>
          </div>

          <div class="col-md-8">
            <div class="row">
              <div class="col-md-5">
                <div class="contact-info">
                  <h4 class="headline">Contact information</h4>
                  <div class="media phone">
                    <div class="media-left"><span class="icon-phone_footer"></span></div>
                    <div class="media-body">
                      <span class="header">Phone:</span>
                      <span class="content">{{session.user?.__theme?.phone ||'(877)659-2159'}}</span>
                    </div>
                  </div>
                </div>
                <div class="media email">
                  <div class="media-left"><span class="icon-email_footer"></span></div>
                  <div class="media-body">
                    <span class="header">Email:</span>
                    <a [ngClass]="{'mgma-link': themeID == 4}" class="content"
                      href="mailto:{{session.user?.__theme?.contact_email||'support@purchaseclinic.com'}}">{{session.user?.__theme?.contact_email||'membersupport@advantagetrustpg.com'}}</a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h4 class="headline d-none d-md-block">&nbsp;</h4>
                <div class="media shedule">
                  <div class="media-left"><span class="icon-clock_footer"></span></div>
                  <div class="media-body">
                    <span class="header">Office Hours:&nbsp;</span>
                    <span class="content">Monday - Friday<br />8:00AM - 5:00PM Central</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container ftr-bottom">
    <div class="copyright">©{{year}} <span
        [attr.data-hidden]="session.user?.theme_id == 2 || session.user?.__theme?.id == 2">PURCHASE CLINIC, LLC.</span>
    </div>
    <div class="payments-and-social">
      <div class="social">
        <ul class="list-unstyled list-inline">
          <li *ngIf="session.user?.__theme?.facebook_url" class="list-inline-item"><a class="icon-facebook"
              href="{{session.user?.__theme?.facebook_url}}"></a></li>
          <li *ngIf="session.user?.__theme?.linkedin_url" class="list-inline-item"><a class="icon-linkedin"
              href="{{session.user?.__theme?.linkedin_url}}"></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<scroll-up></scroll-up>
